import axios from "axios";
import { drnEndpoint, regionEndpoint, regionSearch } from '../endpoints';

// to get the primary category search function except the region search
export const fetchSearchResult = async (searchField: string, searchVal: string | number, offSet: number, token: any) => {
    try {
      const resp = await axios.get(`${drnEndpoint}?searchField=${searchField}&searchVal=${encodeURIComponent(searchVal)}&&offSet=${offSet}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorizationtoken: `Bearer ${token}`
        }
      });
      return resp.data;
    } catch (ex) {
      throw ex;
    }

    // try {
    // const res = await axios.get('http://localhost:3000/mock/drn_data_response.json');
    // return res.data;
    // }
    // catch(ex){
    //   throw ex;
    // } 
  };

  export const fetchFilterSearchResult = async (searchField: string, searchVal: string | number, offSet: number, filterParms: any, token: any) => {
    try {
      // const {document_type, country_name, invoice_number, nomination_number, port_name, region_name, delivery_date, cust_acct_name, vessel_name} = filterParms;
      const document_type = filterParms.document_type ? filterParms.document_type : null;
      const country_name = filterParms.country_name ? filterParms.country_name : null;
      const invoice_number = filterParms.invoice_number ? filterParms.invoice_number.trim() : null
      const nomination_number = filterParms.nomination_number ? filterParms.nomination_number.trim() : null
      const port_name = filterParms.port_name ? filterParms.port_name : null
      const region_name = filterParms.region_name ? filterParms.region_name : null
      const delivery_date = filterParms.delivery_date ? filterParms.delivery_date: null
      const invoice_date = filterParms.invoice_date ? filterParms.invoice_date: null
      const cust_acct_name = filterParms.cust_acct_name ? encodeURIComponent(filterParms.cust_acct_name.trim()) : null
      const vessel_name = filterParms.vessel_name ? filterParms.vessel_name.trim() : null
      const resp = await axios.get(`${drnEndpoint}?searchField=${searchField}&searchVal=${encodeURIComponent(searchVal)}&document_type=${document_type}&filter_country_name=${encodeURIComponent(country_name)}&invoice_number=${invoice_number}&vessel_name=${vessel_name}&cust_acct_name=${cust_acct_name}&nomination_number=${nomination_number}&filter_port_name=${encodeURIComponent(port_name)}&filter_region_name=${encodeURIComponent(region_name)}&delivery_date=${delivery_date}&invoice_date=${invoice_date}&&offSet=${offSet}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorizationtoken: `Bearer ${token}`
        }
      });
      return resp.data;
    } catch (ex) {
      throw ex;
    }
    
    // const res = await axios.get('http://localhost:3000/mock/drn_data_response.json');
    // return res.data;
    // const result = await resp.json();
    
  };

 // to get the region drop down load data 
  export const fetchRegionSearchVals = async (token: any) => {
    try {
      const resp = await axios.get(`${regionEndpoint}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorizationtoken: `Bearer ${token}`
        }
      });
      // const resp = await axios.get('http://localhost:3000/mock/region_res.json');
      return resp.data;
    }
    catch (ex) {
      throw ex;
    }
  }

 // to get the primary category region search 
  export const fetchRegionSearchResult = async (searchField: string, region: string, country: string, port: string, offSet: number, token: any) => {
    try {
      const resp = await axios.get(`${regionSearch}?region_name=${encodeURIComponent(region)}&country_name=${encodeURIComponent(country)}&port_name=${encodeURIComponent(port)}&&offSet=${offSet}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorizationtoken: `Bearer ${token}`
        }
      });
      return resp.data;
    } catch (ex) {
      throw ex;
    }
    //const res = await axios.get('http://localhost:3000/mock/drn_data_response.json');
    //return res.data;
  };

  export const fetchFilterRegionSearchResult = async (searchField: string, region: string, country: string, port: string, offSet: number, filterParms: any, token: any) => {
    try {
      const document_type = filterParms.document_type ? filterParms.document_type : null;
      const country_name = filterParms.country_name ? filterParms.country_name : null;
      const invoice_number = filterParms.invoice_number ? filterParms.invoice_number.trim() : null
      const nomination_number = filterParms.nomination_number ? filterParms.nomination_number.trim() : null
      const port_name = filterParms.port_name ? filterParms.port_name : null
      const region_name = filterParms.region_name ? filterParms.region_name : null
      const delivery_date = filterParms.delivery_date ? filterParms.delivery_date: null
      const invoice_date = filterParms.invoice_date ? filterParms.invoice_date: null
      const cust_acct_name = filterParms.cust_acct_name ? encodeURIComponent(filterParms.cust_acct_name.trim()) : null
      const vessel_name = filterParms.vessel_name ? filterParms.vessel_name.trim() : null
      const resp = await axios.get(`${regionSearch}?region_name=${encodeURIComponent(region)}&country_name=${encodeURIComponent(country)}&port_name=${encodeURIComponent(port)}&country_name=${encodeURIComponent(country_name)}&invoice_number=${invoice_number}&vessel_name=${vessel_name}&cust_acct_name=${cust_acct_name}&nomination_number=${nomination_number}&delivery_date=${delivery_date}&invoice_date=${invoice_date}&document_type=${document_type}&vessel_name=${vessel_name}&filter_region_name=${encodeURIComponent(region_name)}&filter_port_name=${encodeURIComponent(port_name)}&filter_region_name=${encodeURIComponent(region_name)}&&offSet=${offSet}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorizationtoken: `Bearer ${token}`
        }
      });
      return resp.data;
    } catch (ex) {
      
      throw ex;
    }
    //const res = await axios.get('http://localhost:3000/mock/drn_data_response.json');
    //return res.data;
  };