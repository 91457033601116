export const config = {
    auth: { 
        clientId: '9e0bd7d7-8be4-4e73-ad99-81c27f3108b6',
        redirectUrl: 'http://localhost:3000',
        scopes: [
            'user:read'
        ],
        authority: 'https://login.microsoftonline.com/ea80952e-a476-42d4-aaf4-5457852b0f7e',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      }
};