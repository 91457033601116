import React, { useState, createContext, useContext } from "react";
interface IUserContext {
  isAuthorize: boolean;
  setIsAuthorize: React.Dispatch<React.SetStateAction<IUserContext["isAuthorize"]>>;
  isAdmin: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<IUserContext["isAdmin"]>>
  isLoadingLandingPage: boolean;
  setIsLoadingLandingPage: React.Dispatch<React.SetStateAction<IUserContext["isLoadingLandingPage"]>>;
}

const userContext = createContext<any>(null);

export const UserContextProvider = ({children}: React.PropsWithChildren<{}>) => {
  const [isAuthorize, setIsAuthorize] = useState<IUserContext["isAuthorize"]>(false);
  const [isAdmin, setIsAdmin] = useState<IUserContext["isAdmin"]>(false);
  const [isLoadingLandingPage, setIsLoadingLandingPage] = useState<IUserContext["isLoadingLandingPage"]>(false);

  return (
    <userContext.Provider value={{ isAuthorize, setIsAuthorize, isAdmin, setIsAdmin, isLoadingLandingPage, setIsLoadingLandingPage }}>
      {children}
    </userContext.Provider>
  );
};

export const useUser = () => {
  const { isAuthorize, setIsAuthorize, isAdmin, setIsAdmin, isLoadingLandingPage, setIsLoadingLandingPage } = useContext(userContext);
  return { isAuthorize, setIsAuthorize, isAdmin, setIsAdmin, isLoadingLandingPage, setIsLoadingLandingPage };
};
