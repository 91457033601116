import { IPublicClientApplication } from "@azure/msal-browser";
import {
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import "./login.scss";
import { authorize } from "../api/Auth/authorization";
import { useUser } from "../contexts/userContextApi";

const Login: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { setIsAuthorize, setIsAdmin, setIsLoadingLandingPage } = useUser();
  const fetchAuthToken = async (token: any) => {
    setIsLoadingLandingPage(true);
    let res:any = await authorize(token).finally(() =>
      setIsLoadingLandingPage(false)
    );
    sessionStorage.setItem("jwt", res.jwtToken);
    sessionStorage.setItem("isAdmin", res.isAdmin);
    setIsAdmin(res.isAdmin);
    return res;
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log(isAuthenticated, "isAuthenticated-------");

      const accessTokenRequest = {
        account: accounts[0],
        scopes: ["User.Read"],
      };
      const token = accessTokenRequest.account.idToken;
      let res = fetchAuthToken(token);
      res.then((res) => {
        if (
          res ===
          "User is not authorized to access this resource with an explicit deny"
        ) {
          //redirectSignOut(instance);
          sessionStorage.setItem("isUnAuth", "false");
          setIsAuthorize(false);
          navigate("/UnAuthorize");
        } else {
          sessionStorage.setItem("isUnAuth", "true");
          setIsAuthorize(true);
          navigate("/drn");
        }
      });
    }
  }, [isAuthenticated]);

  const redirectSignIn = async (instance: IPublicClientApplication) => {
    await instance.handleRedirectPromise();
    instance.loginRedirect();
  };
  const redirectSignOut = (instance: IPublicClientApplication) => {
    const logoutRequest = {
      postLogoutRedirectUri: `https://dtztk57qeqd06.cloudfront.net/`,
    };

    instance.logoutRedirect(logoutRequest);
  };

  return (
    <UnauthenticatedTemplate>
      {/* <div className="loginContainer">
        <div className="signInSection">
          <div className="signInContainer">
            <span className="welcomeTitle">Sign In Here</span>
            <span className="intro">GME Document Hub</span>
            <Button
              className="signInButton"
              onClick={() => redirectSignIn(instance)}
            >
              Sign in
            </Button>
          </div>
        </div>
        <div className="landingContainer d-flex">
          <img
            src="./images/CastrolLogin.png"
            alt="Login Image"
            style={{ width: "inherit" }}
          />
        </div>
      </div> */}
      <div className="wrapper">
        <div className="login-container">
          <h1>Welcome to GME Document Hub</h1>
          <div className="login-form-container">
            <div className="login-form-content">
              <div className="login-buttons">
                <div className="login-button">
                  <Button
                    className="signInButton"
                    style={{fontWeight: "500"}}
                    onClick={() => redirectSignIn(instance)}
                  >
                    bp staff enter here
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UnauthenticatedTemplate>
  );
};

export default Login;
