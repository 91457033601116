import { Down24, Download16, Remove24 } from "@bphxd/ds-core-react/lib/icons";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import "./QuickSearch.scss";
import SvgSearch24 from "@bphxd/ds-core-react/lib/icons/interface/Search24";
import { Button } from "@bphxd/ds-core-react/lib/components/button/Button";
import { useEffect, useState } from "react";
import { useWindowSize } from "@bphxd/ds-core-react";
//import { fetchRegionSearchVals } from "../../api/DRN/drnsearch";
import { DrnViewModel } from "../../model/DrnViewModel";
import { downloadSelectedDrn } from "../../api/DRN/drndownload";
import { useMsal } from "@azure/msal-react";

export default function QuickSearch({
  onSearch,
  setSearchValsState,
  setTotalRecordsCount,
  setNoDatafound,
  regionData,
  setSelectedField,
  setEnableAgGrid,
}: {
  onSearch: (
    searchField: string,
    searchVal: string,
    region: string,
    country: string,
    port: string,
    offSet: number
  ) => void;
  setSearchValsState: React.Dispatch<
    React.SetStateAction<{
      sField: string;
      sText: string;
      sRegion: string;
      sCountry: string;
      sPort: string;
    }>
  >;
  setTotalRecordsCount: React.Dispatch<React.SetStateAction<number>>;
  setNoDatafound: React.Dispatch<React.SetStateAction<string>>;
  regionData: any;
  setSelectedField: React.Dispatch<React.SetStateAction<string>>;
  setEnableAgGrid: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [searchField, setsearchField] = useState<string>("Select Category");
  const [searchText, setSearchText] = useState<string>("");
  //const [regionData, setRegionData] = useState<any>([]);
  const [region, setRegion] = useState<string>("Select Region");
  const [country, setCountry] = useState<string>("Select Country");
  const [port, setPort] = useState<string>("Select Port");
  const [countryItem, setCountryitem] = useState<any>([]);
  const [portItem, setPortitem] = useState<string[]>([]);
  const [enableCtry, setEnableCtry] = useState<boolean>(false);
  const [enablePort, setEnablePort] = useState<boolean>(false);

  const { instance, accounts } = useMsal();
  const accessTokenRequest = {
    account: accounts[0],
    scopes: ["User.Read"],
  };
  // const token = accessTokenRequest.account.idToken;
  const token = sessionStorage.getItem("jwt");
  const { width } = useWindowSize();

  // useEffect(() => {
  //   // pull all records related to region,country,port on-load of quick search component for the first time.
  //   fetchRegionData();
  // }, []);

  useEffect(() => {
    resetAllRegionFields();
    setSearchText("");
    setNoDatafound("");
  }, [searchField]);

  // const fetchRegionData = async () => {
  //   try {
  //     let res = await fetchRegionSearchVals(token);
  //     setRegionData(res);
  //   } catch (er) {
  //     console.log(er);
  //   }
  // };
  const regx = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';.,_/{}()@|\\":<>\?]/);
  const validateAndSearch = () => {
    if (
      searchField !== "Region" &&
      searchField !== "Customer Account Name" &&
      searchField !== "Country of Supply" &&
      searchField !== "Port" &&
      searchField !== "Vessel"
    ) {
      if (regx.test(searchText)) {
        setNoDatafound(
          "Please only use numbers and letters in the search field as special characters are not supported."
        );
        setSelectedField("");
        setEnableAgGrid(false);
      } else {
        onSearch(searchField, searchText, region, country, port, 0);
        setSearchValsState({
          sField: searchField,
          sText: searchText,
          sRegion: region,
          sCountry: country,
          sPort: port,
        });
      }
    } else {
      onSearch(searchField, searchText, region, country, port, 0);
      setSearchValsState({
        sField: searchField,
        sText: searchText,
        sRegion: region,
        sCountry: country,
        sPort: port,
      });
    }
  };

  const resetAllRegionFields = () => {
    setRegion("Select Region");
    setCountry("Select Country");
    setPort("Select Port");
    setEnableCtry(false);
    setEnablePort(false);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      if (
        searchField !== "Select Category" &&
        (searchText !== "" || region !== "Select Region")
      ) {
        event.preventDefault();
        event.stopPropagation();
        setTotalRecordsCount(0);
        validateAndSearch();
      }
    }
  };
  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
    setNoDatafound("");
  };
  const handleRegionChange = (region: string) => {
    setRegion(region);
    setCountryitem(
      regionData.find((rgn: any) => rgn.region_name === region).country_name
    );
    setEnablePort(false);
    setCountry("Select Country");
    setPort("Select Port");
  };
  const handleCountryChange = (country: string) => {
    setCountry(country);
    setPortitem(countryItem.find((cty: any) => cty.name === country).port_name);
    setEnablePort(true);
    setPort("Select Port");
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-3 col-lg-2 col-12">
          <UncontrolledDropdown size="sm" className="mb-4 ft-size">
            <DropdownToggle
              className="w-100 sch-drpdwn-toggle border-0 rounded-0 ft-size"
              color="#fff"
              caret
            >
              {searchField}
              <Down24 color="#007f00 !important" className="btn-icon-suffix" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-sm w-100 ft-size">
              <DropdownItem
                onClick={() => {
                  setsearchField("Order Number");
                }}
              >
                Order Number
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("ERP Order Number");
                }}
              >
                ERP Order Number
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("UOA (GR) Number");
                }}
              >
                UOA (GR) Number
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("Invoice Number");
                }}
              >
                Invoice Number
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("Customer Account Name");
                }}
              >
                Customer Account Name
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("Region");
                }}
              >
                Region
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("Country of Supply");
                }}
              >
                Country of Supply
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("Port");
                }}
              >
                Port
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("Vessel");
                }}
              >
                Vessel
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {searchField !== "Region" && (
          <div className="col-sm-7 col-lg-5">
            <InputGroup
              className="input-group-merge mb-4 search-input"
              size="sm"
            >
              <Input
                className="form-control-prepended form-control-appended ft-size"
                placeholder={
                  searchField === "Select Category"
                    ? "Search"
                    : searchField === "Order Number" && width > 576
                    ? "Search with Manta Order number or New Marine Order number"
                    : `Search with ${searchField}`
                }
                type="text"
                onChange={onSearchTextChange}
                onKeyDown={onKeyDown}
                value={searchText}
              />
              <div className="input-group-prepend">
                <InputGroupText>
                  <SvgSearch24 color="#007f00" />
                </InputGroupText>
              </div>
              <div className="input-group-append">
                <InputGroupText onClick={() => setSearchText("")}>
                  {searchText !== "" && (
                    <Remove24 style={{ cursor: "pointer" }} />
                  )}
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        )}
        {searchField === "Region" && (
          <>
            <div className="col-sm-3 col-lg-2 col-12 mb-4">
              <UncontrolledDropdown size="sm">
                <DropdownToggle
                  className="w-100 sch-drpdwn-toggle rounded-0 text-truncate ft-size"
                  color="#fff"
                  caret
                >
                  {region}
                  <Down24
                    color="#007f00 !important"
                    className="btn-icon-suffix"
                  />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-sm w-100 dropdown-menu-scroll ft-size">
                  {regionData.map((item: any, index: any) => (
                    <div key={item.region_name}>
                      <DropdownItem
                        onClick={() => {
                          handleRegionChange(item.region_name);
                          setEnableCtry(true);
                        }}
                      >
                        {item.region_name}
                      </DropdownItem>
                      {/* {regionData.length - 1 !== index && (
                        <DropdownItem divider />
                      )} */}
                    </div>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="col-sm-3 col-lg-2 col-12 mb-4">
              <UncontrolledDropdown size="sm">
                <DropdownToggle
                  className="w-100 sch-drpdwn-toggle rounded-0 text-truncate ft-size"
                  color="#fff"
                  caret
                  disabled={!enableCtry}
                >
                  {country}
                  <Down24
                    color="#007f00 !important"
                    className="btn-icon-suffix"
                  />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-sm w-100 dropdown-menu-scroll ft-size">
                  {countryItem.map((item: any, index: any) => (
                    <div key={item.name}>
                      <DropdownItem
                        onClick={() => {
                          handleCountryChange(item.name);
                        }}
                      >
                        {item.name}
                      </DropdownItem>
                      {/* {countryItem.length - 1 !== index && (
                        <DropdownItem divider />
                      )} */}
                    </div>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="col-sm-3 col-lg-2 col-12 mb-4">
              <UncontrolledDropdown size="sm">
                <DropdownToggle
                  className="w-100 sch-drpdwn-toggle rounded-0 text-truncate ft-size"
                  color="#fff"
                  caret
                  disabled={!enablePort}
                >
                  {port}
                  <Down24
                    color="#007f00 !important"
                    className="btn-icon-suffix"
                  />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-sm w-100 dropdown-menu-scroll ft-size">
                  {portItem.map((item: any, index: any) => (
                    <div key={item}>
                      <DropdownItem
                        onClick={() => {
                          setPort(item);
                        }}
                      >
                        {item}
                      </DropdownItem>
                      {/* {portItem.length - 1 !== index && (
                        <DropdownItem divider />
                      )} */}
                    </div>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </>
        )}
        <div className="col-sm-2">
          <Button
            rounded="pill"
            theme="brand"
            level="primary"
            size="sm"
            className="ft-size qik-srch-btn"
            style={{
              width: "78px",
            }}
            disabled={
              searchField !== "Select Category" &&
              (searchText !== "" || region !== "Select Region")
                ? false
                : true
            }
            onClick={() => {
              validateAndSearch();
              setTotalRecordsCount(0);
            }}
          >
            Search
          </Button>
        </div>
        {/* <div
          className={searchField === "Region" ? "col-sm-1" : "col-sm-2"}
        ></div> */}
      </div>
    </>
  );
}
