import axios from "axios";
import { uploadSearch } from '../endpoints';
export const fetchValidationSearch = async (searchField: string, searchVal: string | number, token: any) => {
    try {
      const resp = await axios.get(`${uploadSearch}?searchField=${searchField}&searchVal=${searchVal}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorizationtoken: `Bearer ${token}`
        }
      });
      return resp.data;
    } catch (ex) {
      throw ex;
    }
    
    // const res = await axios.get('http://localhost:3000/mock/valid_drn_search_res.json');
    // return res.data;
    //const result = await resp.json();
    
  };