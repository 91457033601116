import { useEffect, useReducer } from "react";
import Header from "../components/Header/Header";
import AppContext from "../AppContext";
import { initialState, orderReducer } from "../store/orderReducer";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useUser } from "../contexts/userContextApi";

function AppLayout() {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(orderReducer, initialState);
  const {setIsAuthorize,setIsAdmin}=useUser();
  const auth = sessionStorage.getItem("isUnAuth");
  const isAdmin = sessionStorage.getItem("isAdmin");
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated) {
      if (auth === "false") {
        navigate("/UnAuthorize");
        setIsAuthorize(false);
      } else {
        setIsAuthorize(true);
      }
      if (isAdmin === "true") setIsAdmin(true);
      else setIsAdmin(false);
    } else navigate("/");
  }, []);

  return (
    <AuthenticatedTemplate>
      <AppContext.Provider value={{ state, dispatch }}>
        <Header />
        <Outlet />
      </AppContext.Provider>
    </AuthenticatedTemplate>
  );
}

export default AppLayout;
