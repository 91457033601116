import axios from "axios";
import { deleteDocuments } from "../endpoints";

export const deleteSelectedDrn = async (docIdArr: any, token: any) => {
  try {
    const resp = await axios.post(`${deleteDocuments}`, docIdArr, {
      headers: {
        "Content-Type": "application/json",
        Authorizationtoken: `Bearer ${token}`,
      },
    });
    return resp.data;
  } catch (ex) {
    throw ex;
  }
};
