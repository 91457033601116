import React from 'react';
import { OrderActionTypes } from './store/actions';
import { OrderState } from './store/orderReducer';

const AppContext = React.createContext({} as IContextProps);

interface IContextProps {
  state: OrderState;
  dispatch: ({ type }: { type: OrderActionTypes; payload: null | any }) => void;
}
export const useAppContext = (): IContextProps => React.useContext(AppContext);

export default AppContext;
