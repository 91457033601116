import { useEffect, useState } from "react";
import "./Home.scss";
import { useMsal } from "@azure/msal-react";
import { DrnModel } from "../../model/DrnModel";
import { DrnViewModel } from "../../model/DrnViewModel";
import {
  fetchFilterRegionSearchResult,
  fetchFilterSearchResult,
  fetchRegionSearchResult,
  fetchRegionSearchVals,
  fetchSearchResult,
} from "../../api/DRN/drnsearch";
//import MainDataTable from "./MainDataTable";
import QuickSearch from "./QuickSearch";
import DataTable from "./DataTable";
import Filter from "./Filter";
import { Badge, Button, Spinner } from "@bphxd/ds-core-react";
import Pagination from "./Pagination";
import { Download16, Trash16 } from "@bphxd/ds-core-react/lib/icons";
import { downloadSelectedDrn } from "../../api/DRN/drndownload";
import { deleteSelectedDrn } from "../../api/DRN/drndelete";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
function Home(token: any) {
  const [rowData, setRowData] = useState<DrnViewModel[]>([]);
  // const [nonFilteredRowData, setNonFilteredRowData] = useState<DrnViewModel[]>([]);
  // const [searchFieldFilter, setsearchFieldFilter] = useState<string>("");
  // const [searchValFilter, setsearchValFilter] = useState<string>("");
  const [enableAgGrid, setEnableAgGrid] = useState<boolean>(false);
  const [noDatafound, setNoDatafound] = useState<string>("");
  const [selectedField, setSelectedField] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFlatRowsVals, setSelectedFlatRowsVals] = useState<
    DrnViewModel[]
  >([]);
  const [searchValsState, setSearchValsState] = useState({
    sField: "",
    sText: "",
    sRegion: "",
    sCountry: "",
    sPort: "",
  });
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
  const [preVisitedPageData, setPreVisitedPageData] = useState<any>({});
  const [activeFilterPagination, setActiveFilterPagination] =
    useState<boolean>(false);
  const [filterQueryParams, setFilterQueryParams] = useState<any>({});
  const [docTypeItemFilter, setDocTypeItemFilter] = useState<string[]>([]);
  // const [regionDataFilter, setRegionDataFilter] = useState<any>([]);
  const [regionData, setRegionData] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const { instance, accounts } = useMsal();
  const accessTokenRequest = {
    account: accounts[0],
    scopes: ["User.Read"],
  };
  const userEmail = accessTokenRequest.account.username;
  // token = accessTokenRequest.account.idToken;

  token = sessionStorage.getItem("jwt");
  const isAdmin = sessionStorage.getItem("isAdmin");
  useEffect(() => {
    // pull all records related to regions,countrys,ports, document types on-load of quick search component for the first time.
    // this data used in region quick search and region filters and document type filter dropdown list items.
    // region - country - port having relational dependent.
    fetchAllRegionAndDocType();
  }, []);

  const fetchAllRegionAndDocType = async () => {
    try {
      let res = await fetchRegionSearchVals(token);
      setRegionData(res.region_data);
      setDocTypeItemFilter(res.document_type);
    } catch (er) {
      console.log("getRegion" + er);
    }
  };

  const onSearch = async (
    searchField: string,
    searchVal: string,
    region: string,
    country: string,
    port: string,
    offSet: number
  ) => {
    //let filterDataBysearchVal: DrnModel[] = [];
    let finalFilteredData: DrnViewModel[] = [];

    searchField =
      searchField === "Order Number"
        ? "order_number"
        : searchField === "ERP Order Number"
        ? "nomination_number"
        : searchField === "Customer Account Name"
        ? "cust_acct_name"
        : searchField === "UOA (GR) Number"
        ? "order_number"
        : searchField === "Invoice Number"
        ? "invoice_number"
        : searchField === "Region"
        ? "region_name"
        : searchField === "Country of Supply"
        ? "country_name"
        : searchField === "Vessel"
        ? "vessel_name"
        : "port_name";

    country = country !== "Select Country" ? country : "null";
    port = port !== "Select Port" ? port : "null";

    try {
      setIsLoading(true);
      let res =
        searchField !== "region_name"
          ? await fetchSearchResult(
              searchField,
              searchVal.trim(),
              offSet,
              token
            )
          : await fetchRegionSearchResult(
              searchField,
              region,
              country,
              port,
              offSet,
              token
            ); // need to call region search api
      if (res === "no records found") {
        setEnableAgGrid(false);
        if (
          searchField === "order_number" ||
          searchField === "nomination_number" ||
          searchField === "invoice_number"
        ) {
          setNoDatafound(
            "There are no documents available for this order yet. Please upload a document or wait for them to be available."
          );
        } else {
          setNoDatafound(
            "No data found! Please check search/filter parameters"
          );
        }
        setSelectedField("");
      } else if (res.data.length > 0) {
        // if (searchField === "order_number") {
        //   res.map((ele: DrnModel) => {
        //     if (ele.order_number === searchVal.toString())
        //       filterDataBysearchVal.push(ele);
        //   });
        // }
        // if (searchField === "invoice_nuber") {
        //   filterDataBysearchVal = res.filter(
        //     (ele: DrnModel) => ele.invoice_number === searchVal
        //   );
        // }
        // if (searchField === "cust_acct_name") {
        //   filterDataBysearchVal = res.filter(
        //     (ele: DrnModel) =>
        //       ele.cust_acct_name.toLowerCase() === searchVal.toLowerCase()
        //   );
        // }

        finalFilteredData = res.data.map((ele: DrnModel) => {
          return {
            ...ele,
            upload_date:
              ele.upload_date !== null ? new Date(ele.upload_date) : null,
            actl_del_date:
              ele.actl_del_date !== null ? new Date(ele.actl_del_date) : null,
            invoice_date:
              ele.invoice_date !== null
                ? ele.invoice_date
                    .replace(/[^.,-:a-zA-Z0-9 ]/g, "")
                    .split(",")
                    .map((x) => {
                      if (x !== "NULL") return moment(x).format("DD MMM YYYY");
                    })
                    .join("\n")
                : null,
            document_id:
              ele.document_id !== null ? ele.document_id.trim() : null,
            order_number:
              ele.order_number !== null ? ele.order_number.trim() : null,
            invoice_number:
              ele.invoice_number !== null ? ele.invoice_number.trim().replaceAll("No number yet","").replace(/[^,a-zA-Z0-9 ]/g, ""): null,
            nomination_number:
              ele.nomination_number !== null
                ? ele.nomination_number.trim().replace(/[{}"]/g, "")
                : null,
          };
        });
        if (Object.hasOwn(res, "totalRecord")) {
          setTotalRecordsCount(res.totalRecord);
          let x: any = {};
          x[0] = finalFilteredData;
          setPreVisitedPageData(x);
          setActiveFilterPagination(false);
        } else pushIntoVisitedPageDataObj(offSet / 10, finalFilteredData);
        // setDocTypeItemFilter(res.docType);
        // if (Object.hasOwn(res, "region")) setRegionDataFilter(res.region);
        if (finalFilteredData.length > 0) {
          setRowData(finalFilteredData);
          //setNonFilteredRowData(finalFilteredData);
          setEnableAgGrid(true);
          setNoDatafound("");
          setSelectedField(searchField);
        } else {
          setEnableAgGrid(false);
          if (
            searchField === "order_number" ||
            searchField === "nomination_number" ||
            searchField === "invoice_number"
          ) {
            setNoDatafound(
              "There are no documents available for this order yet. Please upload a document or wait for them to be available."
            );
          } else {
            setNoDatafound(
              "No data found! Please check search/filter parameters"
            );
          }
          setSelectedField("");
        }
      } else {
        setEnableAgGrid(false);
        if (
          searchField === "order_number" ||
          searchField === "nomination_number" ||
          searchField === "invoice_number"
        ) {
          setNoDatafound(
            "There are no documents available for this order yet. Please upload a document or wait for them to be available."
          );
        } else {
          setNoDatafound(
            "No data found! Please check search/filter parameters"
          );
        }
        setSelectedField("");
      }
    } catch (ex: any) {
      setEnableAgGrid(false);
      setSelectedField("");
      ex.message === "Request failed with status code 504"
        ? setNoDatafound(
            "It is taking longer than expected to communicate with our servers. Please check your network connection and try again"
          )
        : setNoDatafound(
            "No data found! Please check search/filter parameters"
          );
    } finally {
      setIsLoading(false);
    }
    //setSelectedField(searchField);
    setIsLoading(false);
  };
  const onPageChange = (offSet: number, idx: number) => {
    if (!(idx in preVisitedPageData)) {
      if (!activeFilterPagination)
        onSearch(
          searchValsState.sField,
          searchValsState.sText,
          searchValsState.sRegion,
          searchValsState.sCountry,
          searchValsState.sPort,
          offSet
        );
      else deepFilter(filterQueryParams, offSet);
    } else setRowData(preVisitedPageData[idx]);
  };
  const pushIntoVisitedPageDataObj = (index: number, obj: DrnViewModel[]) => {
    let x = preVisitedPageData;
    x[index] = obj;
    setPreVisitedPageData(x);
  };
  const deepFilter = async (filterParamObj: any, offSet: number) => {
    let finalFilteredData: DrnViewModel[] = [];
    let searchField =
      searchValsState.sField === "Order Number"
        ? "order_number"
        : searchValsState.sField === "ERP Order Number"
        ? "nomination_number"
        : searchValsState.sField === "Customer Account Name"
        ? "cust_acct_name"
        : searchValsState.sField === "UOA (GR) Number"
        ? "order_number"
        : searchValsState.sField === "Invoice Number"
        ? "invoice_number"
        : searchValsState.sField === "Region"
        ? "region_name"
        : searchValsState.sField === "Country of Supply"
        ? "country_name"
        : searchValsState.sField === "Vessel"
        ? "vessel_name"
        : "port_name";
    let region =
      searchValsState.sRegion !== "Select Region"
        ? searchValsState.sRegion
        : "null";
    let country =
      searchValsState.sCountry !== "Select Country"
        ? searchValsState.sCountry
        : "null";
    let port =
      searchValsState.sPort !== "Select Port" ? searchValsState.sPort : "null";
    let searchVal = searchValsState.sText;
    try {
      setIsLoading(true);
      let res =
        searchField !== "region_name"
          ? await fetchFilterSearchResult(
              searchField,
              searchVal.trim(),
              offSet,
              filterParamObj,
              token
            )
          : await fetchFilterRegionSearchResult(
              searchField,
              region,
              country,
              port,
              offSet,
              filterParamObj,
              token
            ); // need to call region search api
      if (res === "no records found") {
        setEnableAgGrid(false);
        setNoDatafound("No data found! Please check search/filter parameters");
      } else if (res.data.length > 0) {
        finalFilteredData = res.data.map((ele: DrnModel) => {
          return {
            ...ele,
            upload_date:
              ele.upload_date !== null ? new Date(ele.upload_date) : null,
            actl_del_date:
              ele.actl_del_date !== null ? new Date(ele.actl_del_date) : null,
            invoice_date:
              ele.invoice_date !== null
                ? ele.invoice_date
                    .replace(/[^.,-:a-zA-Z0-9 ]/g, "")
                    .split(",")
                    .map((x) => {
                      if (x !== "NULL") return moment(x).format("DD MMM YYYY");
                    })
                    .join("\n")
                : null,
            document_id:
              ele.document_id !== null ? ele.document_id.trim() : null,
            order_number:
              ele.order_number !== null ? ele.order_number.trim() : null,
            invoice_number:
              ele.invoice_number !== null ? ele.invoice_number.trim().replaceAll("No number yet","").replace(/[^,a-zA-Z0-9 ]/g, "") : null,
            nomination_number:
              ele.nomination_number !== null
                ? ele.nomination_number.trim().replace(/[{}"]/g, "")
                : null,
          };
        });
        if (Object.hasOwn(res, "totalRecord")) {
          setTotalRecordsCount(res.totalRecord);
          let x: any = {};
          x[0] = finalFilteredData;
          setPreVisitedPageData(x);
          setActiveFilterPagination(true);
        } else pushIntoVisitedPageDataObj(offSet / 10, finalFilteredData);
        // setDocTypeItemFilter(res.docType);
        // if (Object.hasOwn(res, "region")) setRegionDataFilter(res.region);
        if (finalFilteredData.length > 0) {
          setRowData(finalFilteredData);
          //setNonFilteredRowData(finalFilteredData);
          setEnableAgGrid(true);
          setNoDatafound("");
        } else {
          setEnableAgGrid(false);
          setNoDatafound(
            "No data found! Please check search/filter parameters"
          );
        }
      } else {
        setEnableAgGrid(false);
        setNoDatafound("No data found! Please check search/filter parameters");
      }
    } catch (ex: any) {
      setEnableAgGrid(false);
      ex.message === "Request failed with status code 504"
        ? setNoDatafound(
            "It is taking longer than expected to communicate with our servers. Please check your network connection and try again"
          )
        : setNoDatafound(
            "No data found! Please check search/filter parameters"
          );
    } finally {
      setIsLoading(false);
    }
    //setSelectedField(searchField);
    //setIsLoading(false);
  };

  function clearDeepFilter() {
    onSearch(
      searchValsState.sField,
      searchValsState.sText,
      searchValsState.sRegion,
      searchValsState.sCountry,
      searchValsState.sPort,
      0
    );
    setFilterQueryParams({});
    //setActiveFilterPagination(false);
    //setTotalRecordsCount(0);
  }

  const downloadAll = async () => {
    setIsLoading(true);
    const selectedAllRecords = selectedFlatRowsVals.map((elem) => ({
      document_id: elem.document_id,
      document_type: elem.document_type,
      order_number: elem.order_number
    }));
    // let searchField =
    //   searchValsState.sField === "Order Number"
    //     ? "order_number"
    //     : searchValsState.sField === "ERP Order Number"
    //     ? "nomination_number"
    //     : searchValsState.sField === "Customer Account Name"
    //     ? "cust_acct_name"
    //     : searchValsState.sField === "UOA (GR) Number"
    //     ? "order_number"
    //     : searchValsState.sField === "Invoice Number"
    //     ? "invoice_number"
    //     : searchValsState.sField === "Region"
    //     ? "region_name"
    //     : searchValsState.sField === "Country of Supply"
    //     ? "country_name"
    //     : searchValsState.sField === "Vessel"
    //     ? "vessel_name"
    //     : "port_name";
    // let region =
    //   searchValsState.sRegion !== "Select Region"
    //     ? searchValsState.sRegion
    //     : null;
    // let country =
    //   searchValsState.sCountry !== "Select Country"
    //     ? searchValsState.sCountry
    //     : null;
    // let port =
    //   searchValsState.sPort !== "Select Port" ? searchValsState.sPort : null;

    // let document_type = filterQueryParams.document_type
    //   ? filterQueryParams.document_type
    //   : null;
    // let filter_country_name = filterQueryParams.country_name
    //   ? filterQueryParams.country_name
    //   : null;
    // let invoice_number = filterQueryParams.invoice_number
    //   ? filterQueryParams.invoice_number
    //   : null;
    // let nomination_number = filterQueryParams.nomination_number
    //   ? filterQueryParams.nomination_number
    //   : null;
    // let filter_port_name = filterQueryParams.port_name
    //   ? filterQueryParams.port_name
    //   : null;
    // let filter_region_name = filterQueryParams.region_name
    //   ? filterQueryParams.region_name
    //   : null;
    // let delivery_date = filterQueryParams.delivery_date
    //   ? filterQueryParams.delivery_date
    //   : null;
    // let invoice_date = filterQueryParams.invoice_date
    //   ? filterQueryParams.invoice_date
    //   : null;
    // let cust_acct_name = filterQueryParams.cust_acct_name
    //   ? filterQueryParams.cust_acct_name
    //   : null;
    // let vessel_name = filterQueryParams.vessel_name
    //   ? filterQueryParams.vessel_name
    //   : null;
    // let searchVal = searchValsState.sText;
    // let downloadAll_payload;
    // if (selectedAllRecords.length === rowData.length) {
    //   downloadAll_payload = {
    //     documents: selectedAllRecords,
    //     downloadAll: true,
    //     searchField,
    //     searchVal,
    //     region,
    //     country,
    //     port,
    //     document_type,
    //     filter_country_name,
    //     invoice_number,
    //     nomination_number,
    //     filter_port_name,
    //     filter_region_name,
    //     delivery_date,
    //     invoice_date,
    //     cust_acct_name,
    //     vessel_name,
    //   };
    //   console.log(downloadAll_payload);
    // } else {
    //   downloadAll_payload = {
    //     documents: selectedAllRecords,
    //     downloadAll: false,
    //     searchField,
    //     searchVal,
    //     region,
    //     country,
    //     port,
    //     document_type,
    //     filter_country_name,
    //     invoice_number,
    //     nomination_number,
    //     filter_port_name,
    //     filter_region_name,
    //     delivery_date,
    //     invoice_date,
    //     cust_acct_name,
    //     vessel_name,
    //   };
    //   console.log(downloadAll_payload);
    // }
    // setIsLoading(false);
    let res = await downloadSelectedDrn(selectedAllRecords, token).finally(() =>
      setIsLoading(false)
    );
    //selectedFlatRowsVals array containing all selected row object
  };

  const deleteAll = async () => {
    setIsLoading(true);
    const selectedAllRecords = selectedFlatRowsVals.map((elem) => ({
      document_id: elem.document_id,
      document_type: elem.document_type,
    }));
    const final_payload = {
      updatedBy: userEmail,
      record: selectedAllRecords,
    };
    try {
      let res: any = await deleteSelectedDrn(final_payload, token);
      if (res.error === "success") {
        setEnableAgGrid(false);
        setNoDatafound("Selected records deleted successfully");
        setSelectedField("");
      } else {
        setEnableAgGrid(false);
        setNoDatafound("Unable to delete now, Please try again later");
        setSelectedField("");
      }
    } catch {
      setEnableAgGrid(false);
      setNoDatafound("Unable to delete now, Please try again later");
      setSelectedField("");
    } finally {
      setIsLoading(false);
    }
    //selectedFlatRowsVals array containing all selected row object
  };
  const toggle = () => setModal(!modal);
  return (
    <div className="App bg-secondary ft-size">
      <QuickSearch
        onSearch={onSearch}
        setSearchValsState={setSearchValsState}
        setTotalRecordsCount={setTotalRecordsCount}
        setNoDatafound={setNoDatafound}
        regionData={regionData}
        setSelectedField={setSelectedField}
        setEnableAgGrid={setEnableAgGrid}
      />
      {isLoading && (
        <div className="table-loader">
          <Spinner color="brand" />
        </div>
      )}
      <div className="row">
        <div className="col-sm-3 col-lg-2 col-12">
          {selectedField !== "" && (
            <div>
              <Filter
                selectedField={selectedField}
                rowData={rowData}
                //setRowData={setRowData}
                //nonFilteredRowData={nonFilteredRowData}
                setEnableAgGrid={setEnableAgGrid}
                setNoDatafound={setNoDatafound}
                //setIsLoading={setIsLoading}
                deepFilter={deepFilter}
                setFilterQueryParams={setFilterQueryParams}
                clearDeepFilter={clearDeepFilter}
                searchValsState={searchValsState}
                setTotalRecordsCount={setTotalRecordsCount}
                docTypeItem={docTypeItemFilter}
                regionDataFilter={regionData}
              />
            </div>
          )}
        </div>
        <div className="col-sm-9 col-lg-10 col-12 position-relative md-margin">
          {enableAgGrid && (
            <>
              <div className="download-all-badge position-absolute d-flex">
                {isAdmin === "true" && (
                  <div
                    style={
                      selectedFlatRowsVals.length > 0
                        ? { opacity: "1", cursor: "pointer" }
                        : { opacity: "0.5", cursor: "not-allowed" }
                    }
                    onClick={() => {
                      if (selectedFlatRowsVals.length > 0) {
                        setModal(true);
                      }
                    }}
                  >
                    <Badge color="light" pill>
                      <Trash16 />
                      Delete
                    </Badge>
                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle}>Confirm delete</ModalHeader>
                      <ModalBody>
                        Are you sure you want to delete the selected documents?
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          level="secondary"
                          rounded="pill"
                          size="sm"
                          theme="brand"
                          className="clear-button1"
                          onClick={toggle}
                        >
                          No
                        </Button>
                        <Button
                          level="primary"
                          rounded="pill"
                          size="sm"
                          theme="brand"
                          className="search-button1 mx-5"
                          onClick={() => {
                            toggle();
                            deleteAll();
                          }}
                        >
                          Yes
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                )}

                <div
                  style={
                    selectedFlatRowsVals.length > 1
                      ? { opacity: "1", cursor: "pointer", marginLeft: "1rem" }
                      : {
                          opacity: "0.5",
                          cursor: "not-allowed",
                          marginLeft: "1rem",
                        }
                  }
                  onClick={() => {
                    if (selectedFlatRowsVals.length > 1) {
                      downloadAll();
                    }
                  }}
                >
                  <Badge color="light" pill>
                    <Download16 />
                    Download
                  </Badge>
                </div>
              </div>
              <DataTable
                rowData={rowData}
                setSelectedFlatRowsVals={setSelectedFlatRowsVals}
              />
              {totalRecordsCount > 10 && (
                <Pagination
                  totalCount={totalRecordsCount}
                  onPageChange={onPageChange}
                />
              )}
            </>
          )}

          {noDatafound !== "" && (
            <div
              style={{ height: "300px", width: "100%", textAlign: "center" }}
              className="no-Data-div"
            >
              {noDatafound}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
