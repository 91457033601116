import { Table } from "@bphxd/ds-core-react";
import { useState, useEffect } from "react";

export default function Pagination({
  totalCount,
  onPageChange,
}: {
  totalCount: number;
  onPageChange: (offSet: number,idx: number) => void;
}) {
  const [pagination, setPagination] = useState<{ index: number; size: number }>(
    {
      index: 0,
      size: 10,
    }
  );

  // useEffect(() => {
  //   console.log("offset=" + pagination.index * 10);
  //   onPageChange(pagination.index * 10);
  // }, [pagination]);

  useEffect(() => {
    setPagination({
      index: 0,
      size: 10,
    });
  }, [totalCount]);

  const onPageIndexChange = (index: number) => {
    setPagination((x) => ({ ...x, index }));
    onPageChange(index * 10,index);
  };
  return (
    <>
      <div className="mb-4 d-flex justify-content-end">
        <Table.Pagination
          onPageIndexChange={onPageIndexChange}
          pageIndex={pagination.index}
          pageSize={pagination.size}
          totalCount={totalCount}
        />
      </div>
    </>
  );
}
