import { Button, Datepicker } from "@bphxd/ds-core-react";
import {
  Add32,
  Delete32,
  Down24,
  Upload24,
} from "@bphxd/ds-core-react/lib/icons";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Label,
} from "reactstrap";
import { RecordValidModel } from "../../model/RecordValidModel";
import moment from "moment";

export default function UploadForm({
  validDrn,
  onUploadSubmit,
  userEmail,
  setIsLoading,
  docTypes,
  filecount,
  allDocTypes,
  srhField,
}: {
  validDrn: RecordValidModel[];
  onUploadSubmit: (upData: any) => Promise<unknown>;
  userEmail: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  docTypes: any[];
  allDocTypes: any[];
  filecount: string | null;
  srhField: string;
}) {
  //const [inputs, setInputs] = useState([{ firstName: "", lastName: "" }]);
  const [docInputs, setDocInputs] = useState<
    {
      document_type: string;
      order_number: string;
      delivery_date: string;
      file: File | null;
      created_by: string;
    }[]
  >([
    {
      document_type: "Choose",
      order_number: validDrn[0].order_number,
      delivery_date: validDrn[0].actl_del_date,
      file: null,
      created_by: userEmail,
    },
  ]);
  const [disabledUpload, setDisableUpload] = useState<boolean>(true);
  const [modal, setModal] = useState<boolean>(false);

  const deliveryDateRefArr = useRef<any>([]);
  const docFileInpRefArr = useRef<any>([]);

  useEffect(() => {
    validateFields();
  }, [docInputs]);

  //   const handleAddInput = () => {
  //     setInputs([...inputs, { firstName: "", lastName: "" }]);
  //   };
  const handleAddDocInput = () => {
    setDisableUpload(true);
    setDocInputs([
      ...docInputs,
      {
        document_type: "Choose",
        order_number: validDrn[0].order_number,
        delivery_date: validDrn[0].actl_del_date,
        file: null,
        created_by: userEmail,
      },
    ]);
  };
  const handleDeleteDocRow = (index: number) => {
    let newArray = [...docInputs];
    newArray.splice(index, 1);
    setDocInputs(newArray);
    //validateFields();
  };
  // const handleChange = (
  //   event: ChangeEvent<HTMLInputElement>,
  //   index: number
  // ) => {
  //   let { name, value } = event.target;
  //   let onChangeValue: any = [...inputs];
  //   onChangeValue[index][name] = value;
  //   setInputs(onChangeValue);
  // };
  const handleDocTypeChange = (docType: string, index: number) => {
    let docModal = docTypes.includes(docType);
    console.log(docModal);
    let onChangeValue: any = [...docInputs];
    onChangeValue[index]["document_type"] = docType;
    setDocInputs(onChangeValue);
    setModal(docModal);
    //validateFields();
  };
  const handleDelDateChange = (date: string, index: number) => {
    let onChangeValue: any = [...docInputs];
    onChangeValue[index]["delivery_date"] = date;
    setDocInputs(onChangeValue);
    //validateFields();
  };
  const handlefileChange = (files: FileList | null, index: number) => {
    let div = document.getElementById(`inv_${index}`);
    let div_size_text = document.getElementById(`inv_size_${index}`);
    let div_gr = document.getElementById(`inv_gr_${index}`);
    if (srhField === "UOA (GR) Number") {
      if (!files?.[0].name.includes(".dat")) {
        div_gr?.classList.remove("val_gr_doc");
        div_gr?.classList.add("inv_gr_doc");
        div?.classList.remove("inv_doc");
        div?.classList.add("val_doc");
        div_size_text?.classList.remove("inv_doc_size");
        div_size_text?.classList.add("val_doc_size");
        let onChangeValue: any = [...docInputs];
        onChangeValue[index]["file"] = null;
        setDocInputs(onChangeValue);
      } else {
        if (files?.[0].size > 2097152) {
          div_gr?.classList.remove("inv_gr_doc");
          div_gr?.classList.add("val_gr_doc");
          div?.classList.remove("inv_doc");
          div?.classList.add("val_doc");
          div_size_text?.classList.remove("val_doc_size");
          div_size_text?.classList.add("inv_doc_size");
          let onChangeValue: any = [...docInputs];
          onChangeValue[index]["file"] = null;
          setDocInputs(onChangeValue);
          //validateFields();
        } else {
          div_gr?.classList.remove("inv_gr_doc");
          div_gr?.classList.add("val_gr_doc");
          div?.classList.remove("inv_doc");
          div?.classList.add("val_doc");
          div_size_text?.classList.remove("inv_doc_size");
          div_size_text?.classList.add("val_doc_size");
          let onChangeValue: any = [...docInputs];
          onChangeValue[index]["file"] = files?.[0];
          setDocInputs(onChangeValue);
          //validateFields();
        }
      }
    } else {
      if (
        !(
          files?.[0].name.includes(".pdf") ||
          files?.[0].name.includes(".PDF") ||
          files?.[0].name.includes(".jpg") ||
          files?.[0].name.includes(".jpeg") ||
          files?.[0].name.includes(".tiff") ||
          files?.[0].name.includes(".tif")
        )
      ) {
        div?.classList.remove("val_doc");
        div?.classList.add("inv_doc");
        div_size_text?.classList.remove("inv_doc_size");
        div_size_text?.classList.add("val_doc_size");
        div_gr?.classList.remove("inv_gr_doc");
        div_gr?.classList.add("val_gr_doc");
        let onChangeValue: any = [...docInputs];
        onChangeValue[index]["file"] = null;
        setDocInputs(onChangeValue);
        //validateFields();
      } else {
        if (files?.[0].size > 2097152) {
          div?.classList.remove("inv_doc");
          div?.classList.add("val_doc");
          div_size_text?.classList.remove("val_doc_size");
          div_size_text?.classList.add("inv_doc_size");
          div_gr?.classList.remove("inv_gr_doc");
          div_gr?.classList.add("val_gr_doc");
          let onChangeValue: any = [...docInputs];
          onChangeValue[index]["file"] = null;
          setDocInputs(onChangeValue);
          //validateFields();
        } else {
          div?.classList.remove("inv_doc");
          div?.classList.add("val_doc");
          div_size_text?.classList.remove("inv_doc_size");
          div_size_text?.classList.add("val_doc_size");
          div_gr?.classList.remove("inv_gr_doc");
          div_gr?.classList.add("val_gr_doc");
          let onChangeValue: any = [...docInputs];
          onChangeValue[index]["file"] = files?.[0];
          setDocInputs(onChangeValue);
          //validateFields();
        }
      }
    }
  };
  const validateFields = () => {
    let allow_upload_flag = false;
    for (let i = 0; i < docInputs.length; i++) {
      if (
        docInputs[i].document_type === "Choose" ||
        docInputs[i].file === null ||
        docInputs[i].delivery_date === null ||
        docInputs[i].delivery_date === "" ||
        docInputs[i].delivery_date === "Invalid date"
      ) {
        allow_upload_flag = false;
        break;
      } else allow_upload_flag = true;
    }
    setDisableUpload(!allow_upload_flag);
  };
  //   const handleDeleteInput = (index: number) => {
  //     const newArray = [...inputs];
  //     newArray.splice(index, 1);
  //     setInputs(newArray);
  //   };
  const clearAllRowFields = () => {
    // let cleanedRows = docInputs.map((docInp) => {
    //   return {
    //     ...docInp,
    //     document_type: "Choose",
    //     actl_del_date: validDrn[0].actl_del_date,
    //     file: null,
    //   };
    // });
    // docInputs.forEach((ele, index) => {
    //   deliveryDateRefArr.current[index]?.flatpickr.setDate(
    //     new Date(validDrn[0].actl_del_date)
    //   );
    //   document.getElementById(`inv_${index}`)?.classList.remove("inv_doc");
    //   document.getElementById(`inv_${index}`)?.classList.add("val_doc");
    // });
    //setDocInputs(cleanedRows);
    setDocInputs([
      {
        document_type: "Choose",
        order_number: validDrn[0].order_number,
        delivery_date: validDrn[0].actl_del_date,
        file: null,
        created_by: userEmail,
      },
    ]);
    document.getElementById(`inv_0`)?.classList.remove("inv_doc");
    document.getElementById(`inv_0`)?.classList.add("val_doc");
    document.getElementById(`inv_size_0`)?.classList.remove("inv_doc_size");
    document.getElementById(`inv_size_0`)?.classList.add("val_doc_size");
    document.getElementById(`inv_gr_0`)?.classList.remove("inv_gr_doc");
    document.getElementById(`inv_gr_0`)?.classList.add("val_gr_doc");
    setDisableUpload(true);
    deliveryDateRefArr.current[0].flatpickr.setDate(validDrn[0].actl_del_date);
  };
  const validateAndUpload = () => {
    setIsLoading(true);
    docInputs.forEach((doc, index) => {
      let fc = 0;
      if (filecount === null) {
        fc = fc + index;
      } else {
        fc = parseInt(filecount) + index;
      }
      if (doc.file) {
        let temp_file = null;
        let filename = doc.file?.name;
        let filetype = doc.file?.type;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(doc.file);
        fileReader.onload = function (e) {
          temp_file = e.target?.result?.toString().split(",")[1];
          let res = onUploadSubmit({
            ...doc,
            file: temp_file,
            file_name: filename,
            file_type: filetype,
            fileCount: fc.toString(),
          });
          res.finally(() => {
            if (index === docInputs.length - 1) {
              setIsLoading(false);
            }
          });
        };
      }
    });
  };
  const toggle = () => setModal(!modal);
  return (
    <>
      <div className="align-scroll">
        {
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Attention</ModalHeader>
            <ModalBody>
              There is a document already uploaded with this document type. Do
              you still want to continue the upload with this document type?
            </ModalBody>
            <ModalFooter>
              <Button
                level="secondary"
                rounded="pill"
                size="sm"
                theme="brand"
                className="clear-button1"
                onClick={toggle}
              >
                No
              </Button>
              <Button
                level="primary"
                rounded="pill"
                size="sm"
                theme="brand"
                className="search-button1 mx-5"
                onClick={toggle}
              >
                Yes
              </Button>
            </ModalFooter>
          </Modal>
        }

        {docInputs.map((item: any, index: number) => (
          <div className="container mx-0" key={index}>
            <div className="row grid-col-scroll" style={{ columnGap: "1rem" }}>
              <div
                className="mb-3 col-sm-2 col-12 w-20"
                style={{ fontSize: "0.8125rem" }}
              >
                <div
                  className="row mb-3 ft-size"
                  style={{ paddingLeft: "15px", flexWrap: "nowrap" }}
                >
                  Document Type
                  <span className="text-danger col"> *</span>
                </div>
                <div className="row width-fr-small">
                  <UncontrolledDropdown size="sm">
                    <DropdownToggle
                      className={
                        docInputs[index]["document_type"] === "Choose"
                          ? "w-100 sch-drpdwn-toggle-uld rounded-0 text-secondary upd-form-ele-border ft-size"
                          : "w-100 sch-drpdwn-toggle-uld rounded-0 upd-form-ele-border ft-size"
                      }
                      color="#fff"
                      caret
                    >
                      {docInputs[index]["document_type"]}
                      <Down24
                        color="#007f00 !important"
                        className="btn-icon-suffix down-arrow-color"
                      />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-sm w-100 ft-size">
                      {allDocTypes.map((item: any) => (
                        <div key={item}>
                          <DropdownItem
                            onClick={(e) => {
                              handleDocTypeChange(item, index);
                            }}
                          >
                            {item}
                          </DropdownItem>
                          {/* {regionData.length - 1 !== index && (
                        <DropdownItem divider />
                      )} */}
                        </div>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div
                className="col-sm-2 mb-3 col-12 w-20"
                style={{ fontSize: "0.8125rem" }}
              >
                <div
                  className="row mb-3 ft-size"
                  style={{
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    flexWrap: "nowrap",
                  }}
                >
                  Order Number
                </div>
                <div
                  className="row width-fr-small"
                  style={{
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    flexWrap: "nowrap",
                  }}
                >
                  <Input
                    name="firstName"
                    bsSize="sm"
                    type="text"
                    value={validDrn[0].order_number}
                    readOnly
                    className="upd-form-ele-border upd-form-ele-border ft-size"
                  />
                </div>
              </div>
              <div
                className="col-sm-2 mb-3 col-12 w-20"
                style={{ fontSize: "0.8125rem" }}
              >
                <div
                  className="row mb-3 ft-size"
                  style={{ paddingLeft: "15px", flexWrap: "nowrap" }}
                >
                  Delivery Date
                  <span className="text-danger col"> *</span>
                </div>
                <div className="row width-fr-small">
                  <Datepicker
                    placeholder=""
                    className="datepicker1"
                    id={`date_${index}`}
                    ref={(ref) => {
                      deliveryDateRefArr.current[index] = ref;
                    }}
                    options={{
                      onClose: function (selectedDates: [], dateStr: string) {
                        console.log(
                          "delivery date",
                          moment(dateStr).format("YYYY-MM-DD")
                        );
                        console.log("delivery date", selectedDates);
                        handleDelDateChange(
                          moment(dateStr).format("YYYY-MM-DD"),
                          index
                        );
                      },
                      onChange: function (selectedDates: [], dateStr: string) {
                        console.log(
                          "delivery date",
                          moment(dateStr).format("YYYY-MM-DD")
                        );
                        console.log("delivery date", selectedDates);
                        handleDelDateChange(
                          moment(dateStr).format("YYYY-MM-DD"),
                          index
                        );
                      },
                      defaultDate: validDrn[0].actl_del_date,
                      dateFormat: "Z",
                      altFormat: "d-M-Y",
                      ariaDateFormat: "d-M-Y",
                      disableMobile: true,
                      maxDate: new Date(),
                    }}
                  />
                </div>
              </div>
              <div
                className="col-sm-2 mb-3 col-12 w-20"
                style={{ fontSize: "0.8125rem" }}
              >
                <div
                  className="row mb-3 docName-field ft-size"
                  style={{ paddingLeft: "15px", flexWrap: "nowrap" }}
                >
                  Document Name
                  <span className="text-danger col">
                    {" "}
                    *
                    <div id={`inv_${index}`} className="val_doc ft-size">
                      {"  "}Supported formats (pdf, jpeg, jpg, tiff, tif)
                    </div>
                    <div
                      id={`inv_size_${index}`}
                      className="val_doc_size ft-size"
                    >
                      {"  "}File size should not exceed 2MB
                    </div>
                    <div id={`inv_gr_${index}`} className="val_gr_doc ft-size">
                      {"  "}Supported formats .dat file for GR number
                    </div>
                  </span>
                </div>
                <div className="row width-fr-small">
                  <div className="mb-4 container">
                    <Input
                      type="file"
                      name={`file_${index}`}
                      id={`file_${index}`}
                      accept={
                        srhField !== "UOA (GR) Number"
                          ? "image/jpg, image/jpeg, image/tiff, .pdf, .PDF"
                          : ".dat"
                      }
                      ref={(ref) => {
                        docFileInpRefArr.current[index] = ref;
                      }}
                      onChange={(e) => {
                        handlefileChange(e.target?.files, index);
                      }}
                      onClick={(event) => {
                        event.currentTarget.value = "";
                      }}
                      style={{ display: "none" }}
                    />
                    <Label
                      for={`file_${index}`}
                      style={{ cursor: "pointer", width: "100%" }}
                    >
                      <div className="row pding-fr-sm">
                        <div
                          style={{ height: "40px" }}
                          className={
                            docInputs[index]["file"] !== null
                              ? "docUld-file-name col-8 col-md-5 col-xl-6 col-xxl-7 upd-form-ele-border upd-file-text"
                              : "docUld-file-name text-secondary txt-size col-8 col-md-5 col-xl-6 col-xxl-7 upd-form-ele-border upd-file-text"
                          }
                        >
                          {docInputs[index]["file"] !== null
                            ? docInputs[index]["file"]?.name
                            : "Choose"}
                        </div>
                        <div
                          style={{ height: "40px" }}
                          className="docUld-text-icon text-secondary col-4 col-md-7 col-xl-6 col-xxl-5 upd-file-lable ft-size"
                        >
                          Upload
                          <Upload24 />
                        </div>
                      </div>
                    </Label>
                  </div>
                </div>
              </div>
              {index === docInputs.length - 1 && index !== 0 && (
                <div
                  className="col-sm-2 align-self-center"
                  style={{ width: "fit-content", cursor: "pointer" }}
                  onClick={() => {
                    handleDeleteDocRow(index);
                  }}
                >
                  <Delete32 />
                </div>
              )}
              {index === docInputs.length - 1 && (
                <div
                  className={
                    index === docInputs.length - 1 && index !== 0
                      ? "col-sm-2 align-self-center left-margin-fr-md"
                      : "col-sm-2 align-self-center"
                  }
                  style={{ width: "fit-content", cursor: "pointer" }}
                  onClick={() => {
                    handleAddDocInput();
                  }}
                >
                  <Add32 />
                </div>
              )}
            </div>
            {/* <div className="row" style={{ columnGap: "1rem" }}>
              <div className="col-sm-2" style={{ width: "20%" }}>
                <UncontrolledDropdown size="sm">
                  <DropdownToggle
                    className="w-100 sch-drpdwn-toggle-uld rounded-0 border"
                    color="#fff"
                    caret
                  >
                    {docInputs[index]["document_type"]}
                    <Down24
                      color="#007f00 !important"
                      className="btn-icon-suffix"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-sm w-100 shadow-xs">
                    <DropdownItem
                      onClick={(e) => {
                        handleDocTypeChange("DRN", index);
                      }}
                    >
                      DRN
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        handleDocTypeChange("BOL", index);
                      }}
                    >
                      BOL
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        handleDocTypeChange("PL", index);
                      }}
                    >
                      PL
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        handleDocTypeChange("PDCL", index);
                      }}
                    >
                      PDCL
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        handleDocTypeChange("Tax Documents", index);
                      }}
                    >
                      Tax Documents
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        handleDocTypeChange("Other", index);
                      }}
                    >
                      Other
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="col-sm-2" style={{ width: "20%" }}>
                <Input
                  name="firstName"
                  bsSize="sm"
                  type="text"
                  value={validDrn[0].order_number}
                  readOnly
                />
              </div>
              <div className="col-sm-2" style={{ width: "20%" }}>
                <Datepicker
                  placeholder=""
                  className="datepicker1"
                  id={`date_${index}`}
                  ref={(ref) => {
                    deliveryDateRefArr.current[index] = ref;
                  }}
                  options={{
                    onClose: function (selectedDates: [], dateStr: string) {
                      console.log("delivery date", dateStr);
                      console.log("delivery date", selectedDates);
                      handleDelDateChange(dateStr, index);
                    },
                    defaultDate: validDrn[0].actl_del_date,
                    dateFormat: "Z",
                    altFormat: "d-M-Y",
                    ariaDateFormat: "d-M-Y",
                    disableMobile: true,
                  }}
                />
              </div>
              <div className="col-sm-2" style={{ width: "20%" }}>
                <InputGroup className="mb-4" size="sm">
                  <Input
                    type="file"
                    name={`file_${index}`}
                    id={`file_${index}`}
                    accept="image/jpg, image/jpeg, image/tif, .pdf"
                    ref={(ref) => {
                      docFileInpRefArr.current[index] = ref;
                    }}
                    onChange={(e) => {
                      handlefileChange(e.target?.files, index);
                    }}
                    style={{ display: "none" }}
                  />
                  <Label for={`file_${index}`} style={{ cursor: "pointer" }}>
                    <div className="docUld-file-name">
                      {docInputs[index]["file"] !== null
                        ? docInputs[index]["file"]?.name
                        : "Choose"}
                    </div>
                    <span className="docUld-text-icon">
                      Upload
                      <UploadWeb24 />
                    </span>
                  </Label>
                </InputGroup>
              </div>
              {index === docInputs.length - 1 && index !== 0 && (
                <div
                  className="col-sm-2"
                  style={{ width: "fit-content", cursor: "pointer" }}
                  onClick={() => {
                    handleDeleteDocRow(index);
                  }}
                >
                  <Delete32 />
                </div>
              )}
              {index === docInputs.length - 1 && (
                <div
                  className="col-sm-2"
                  style={{ width: "fit-content", cursor: "pointer" }}
                  onClick={() => {
                    handleAddDocInput();
                  }}
                >
                  <Add32 />
                </div>
              )}
            </div> */}
            {/* <input
              name="lastName"
              type="text"
              value={item.lastName}
              onChange={(event) => handleChange(event, index)}
            />
            {inputs.length > 1 && (
              <button onClick={() => handleDeleteInput(index)}>Delete</button>
            )}
            {index === inputs.length - 1 && (
              <button onClick={() => handleAddInput()}>Add</button>
            )} */}
          </div>
        ))}
        <div className="filter-btn-con">
          <Button
            level="primary"
            rounded="pill"
            size="sm"
            theme="brand"
            className="search-button1 ft-size"
            disabled={disabledUpload}
            onClick={() => {
              validateAndUpload();
            }}
          >
            Submit
          </Button>
          <Button
            level="secondary"
            rounded="pill"
            size="sm"
            theme="brand"
            className="clear-button1 ft-size"
            onClick={clearAllRowFields}
          >
            Clear
          </Button>
        </div>
        {/* <div className="body"> {JSON.stringify(docInputs)} </div> */}
      </div>
    </>
  );
}
