import { authorization } from "../endpoints";
import axios from "axios";

export const authorize = async (token: any) => {
  // const config = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`
  //   },
  // };
  try {
    const resp = await axios.get(`${authorization}`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    // const resp = await axios.get('http://localhost:3000/mock/auth_token.json');
    return resp.data;
    //return "User is not authorized to access this resource with an explicit deny"
  }
  catch (ex) {
    throw ex;
  }
};
