import { Button, Datepicker } from "@bphxd/ds-core-react";
import { Down16, Remove24 } from "@bphxd/ds-core-react/lib/icons";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DrnViewModel } from "../../model/DrnViewModel";
//import { fetchFilterSearchResult, fetchFilterRegionSearchResult } from "../../api/DRN/drnsearch";
import moment from "moment";

export default function Filter({
  selectedField,
  rowData,
  //setRowData,
  //nonFilteredRowData,
  setEnableAgGrid,
  setNoDatafound,
  //setIsLoading,
  deepFilter,
  setFilterQueryParams,
  clearDeepFilter,
  searchValsState,
  setTotalRecordsCount,
  docTypeItem,
  regionDataFilter,
}: {
  selectedField: string;
  rowData: DrnViewModel[];
  //setRowData: Dispatch<SetStateAction<DrnViewModel[]>>;
  //nonFilteredRowData: DrnViewModel[];
  setEnableAgGrid: Dispatch<SetStateAction<boolean>>;
  setNoDatafound: React.Dispatch<React.SetStateAction<string>>;
  //setIsLoading: Dispatch<SetStateAction<boolean>>;
  deepFilter: (filterParamObj: any, offSet: number) => Promise<void>;
  setFilterQueryParams: Dispatch<any>;
  clearDeepFilter: () => void;
  searchValsState: {
    sField: string;
    sText: string;
    sRegion: string;
    sCountry: string;
    sPort: string;
  };
  setTotalRecordsCount: React.Dispatch<React.SetStateAction<number>>;
  docTypeItem: string[];
  regionDataFilter: any;
}) {
  const [enableDocTypeDropDown, setEnbleDocTypeDropDown] =
    useState<boolean>(false);
  const [enableDocTypeDropDown1, setEnbleDocTypeDropDown1] =
    useState<boolean>(false);
  const [enableNominationField, setEnableNominationField] =
    useState<boolean>(false);
  const [enableDeliveryDateField, setEnbleDeliveryDateField] =
    useState<boolean>(false);
  const [enableInvoiceDateField, setEnbleInvoiceDateField] =
    useState<boolean>(false);
  const [enableInvoiceNumField, setEnbleInvoiceNumField] =
    useState<boolean>(false);
  const [enableBusiCnlDropDown, setEnbleBusiCnlDropDown] =
    useState<boolean>(false);
  const [enableCusSalArea, setEnbleCusSalArea] = useState<boolean>(false);
  const [enableCusAccName, setEnbleCusAccName] = useState<boolean>(false);
  const [enableCusAccName1, setEnbleCusAccName1] = useState<boolean>(false);
  const [enableRegionDropDown, setEnableRegionDropDown] =
    useState<boolean>(false);
  const [enableRegionDropDown1, setEnableRegionDropDown1] =
    useState<boolean>(false);
  const [enableContyDropDown, setEnableContyDropDown] =
    useState<boolean>(false);
  const [enablePortDropDown, setEnablePortDropDown] = useState<boolean>(false);
  const [enableVesselField, setEnableVesselField] = useState<boolean>(false);

  const [documentType, setDocumentType] = useState<string>("Document Type");
  const [businessChannel, setBusinessChannel] =
    useState<string>("Business Channel");
  const [region, setRegion] = useState<string>("Region");
  const [country, setCountry] = useState<string>("Country");
  const [port, setPort] = useState<string>("Port");
  const [nominationNum, setNominationNum] = useState<string>("");
  const [invoiceNum, setInvoiceNum] = useState<string>("");
  const [cusSalesArea, setCusSalesArea] = useState<string>("");
  const [cusName, setCusName] = useState<string>("");
  const [vessel, setVessel] = useState<string>("");
  //const [docTypeItem, setDocTypeItem] = useState<string[]>([]);
  const [busiUnitItem, setBusiUnitItem] = useState<string[]>([]);
  //const [regionItem, setRegionItem] = useState<string[]>([]);
  const [countryItem, setCuntryItem] = useState<any[]>([]);
  const [portItem, setPortItem] = useState<string[]>([]);
  const [disableCtry, setDisableCtry] = useState<boolean>(true);
  const [disablePrt, setDisablePrt] = useState<boolean>(true);
  const [invoiceDate, setInvoiceDate] = useState<string>("");
  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const deliveryDateRefComp = useRef<any>(null);
  const invoiceDateRefComp = useRef<any>(null);

  // useEffect(() => {
  //   clearfilters();
  // }, [nonFilteredRowData]);
  useEffect(()=>{
    resetFilterInputFields();
  },[searchValsState]);

  useEffect(() => {
    resetFilterInputFields();
    if (
      selectedField === "order_number" ||
      selectedField === "nomination_number" ||
      selectedField === "isp_gq/gr_number"
    ) {
      setEnbleDocTypeDropDown(true);
      setEnableNominationField(true);
      setEnbleDeliveryDateField(true);
      setEnbleInvoiceDateField(true);
      setEnbleInvoiceNumField(true);
      setEnbleBusiCnlDropDown(false);
      setEnbleCusSalArea(false);
      setEnbleCusAccName(true);
      setEnableRegionDropDown(false);
      setEnableContyDropDown(false);
      setEnablePortDropDown(false);
      setEnableVesselField(false);
      setEnbleDocTypeDropDown1(false);
      setEnbleCusAccName1(false);
      setEnableRegionDropDown1(false);
    } else if (selectedField === "invoice_number") {
      setEnbleDocTypeDropDown(true);
      setEnableNominationField(true);
      setEnbleDeliveryDateField(true);
      setEnbleInvoiceDateField(true);
      setEnbleBusiCnlDropDown(false);
      setEnbleCusSalArea(false);
      setEnbleCusAccName(true);
      setEnableRegionDropDown(false);
      setEnableContyDropDown(false);
      setEnablePortDropDown(false);
      setEnableVesselField(false);
      setEnbleDocTypeDropDown1(false);
      setEnbleCusAccName1(false);
      setEnableRegionDropDown1(false);
    }
    else if (selectedField === "cust_acct_name") {
      setEnbleDocTypeDropDown(false);
      setEnableNominationField(true);
      setEnbleDeliveryDateField(true);
      setEnbleInvoiceDateField(true);
      setEnbleInvoiceNumField(true);
      setEnableRegionDropDown(true);
      setEnableContyDropDown(true);
      setEnablePortDropDown(true);
      setEnableVesselField(true);
      setEnbleCusSalArea(false);
      setEnbleCusAccName(false);
      setEnbleBusiCnlDropDown(false);
      setEnbleDocTypeDropDown1(true);
      setEnbleCusAccName1(false);
      setEnableRegionDropDown1(false);
    } else if (selectedField === "country_name") {
      setEnbleDocTypeDropDown(false);
      setEnableNominationField(true);
      setEnbleDeliveryDateField(true);
      setEnbleInvoiceDateField(true);
      setEnbleInvoiceNumField(true);
      setEnableRegionDropDown(false);
      setEnableContyDropDown(false);
      setEnablePortDropDown(false);
      setEnableVesselField(true);
      setEnbleCusSalArea(false);
      setEnbleCusAccName(true);
      setEnbleBusiCnlDropDown(false);
      setDisableCtry(false);
      setDisablePrt(false);
      setEnbleCusAccName1(false);
      setEnbleDocTypeDropDown1(true);
      setEnableRegionDropDown1(false);
    } else if (selectedField === "port_name") {
      setEnbleDocTypeDropDown(false);
      setEnableNominationField(true);
      setEnbleDeliveryDateField(true);
      setEnbleInvoiceDateField(true);
      setEnbleInvoiceNumField(true);
      setEnableRegionDropDown(false);
      setEnableContyDropDown(false);
      setEnablePortDropDown(false);
      setEnableVesselField(true);
      setEnbleCusSalArea(false);
      setEnbleCusAccName(true);
      setEnbleBusiCnlDropDown(false);
      setEnbleDocTypeDropDown1(true);
      setEnbleCusAccName1(false);
      setEnableRegionDropDown1(false);
      setDisableCtry(false);
      setDisablePrt(false);
    } else if (selectedField === "region_name") {
      setEnbleDocTypeDropDown(false);
      setEnableNominationField(true);
      setEnbleDeliveryDateField(true);
      setEnbleInvoiceDateField(true);
      setEnbleInvoiceNumField(true);
      setEnableRegionDropDown(false);
      setEnableContyDropDown(false);
      setEnablePortDropDown(false);
      setEnableVesselField(true);
      setEnbleCusSalArea(false);
      setEnbleCusAccName(true);
      setEnbleBusiCnlDropDown(false);
      setEnbleDocTypeDropDown1(true);
      setEnbleCusAccName1(false);
      setDisableCtry(false);
      setDisablePrt(false);
    } else if (selectedField === "vessel_name") {
      setEnbleDocTypeDropDown(false);
      setEnableNominationField(true);
      setEnbleDeliveryDateField(true);
      setEnbleInvoiceDateField(true);
      setEnbleInvoiceNumField(true);
      setEnableRegionDropDown(true);
      setEnableContyDropDown(true);
      setEnablePortDropDown(true);
      setEnableVesselField(false);
      setEnbleCusSalArea(false);
      setEnbleCusAccName(false);
      setEnbleBusiCnlDropDown(false);
      setEnbleDocTypeDropDown1(true);
      setEnbleCusAccName1(true);
    }
  }, [selectedField]);

  // useEffect(() => {
  //   pullDropDownItems();
  //   //only document type, business channel and region dropdown items will come from current table data(filter applied)
  //   //rest input fields is not dependent on current table data, it can filter the main data(not filter applied)
  // }, [rowData]);

  // useEffect(() => {
  //   //pulling country dropdown items from current table data based on region selection(filter applied)
  //   if (region !== "Region") {
  //     setDisableCtry(false);
  //     setDisablePrt(true);
  //     setCountry("Country");
  //     setPort("Port");
  //     let cuntrylookup: any = {};
  //     let cuntryResult: string[] = [];
  //     rowData.forEach((item: DrnViewModel) => {
  //       if (item.region_name === region) {
  //         if (!(item.country_name in cuntrylookup)) {
  //           if (item.country_name !== null) {
  //             cuntrylookup[item.country_name] = 1;
  //             cuntryResult.push(item.country_name);
  //           }
  //         }
  //       }
  //     });
  //     setCuntryItem(cuntryResult);
  //   }
  // }, [region]);

  // useEffect(() => {
  //   //pulling port dropdown items from current table data based on country selection(filter applied)
  //   if (country !== "Country") {
  //     setDisablePrt(false);
  //     setPort("Port");
  //     let portlookup: any = {};
  //     let portResult: string[] = [];
  //     rowData.forEach((item: DrnViewModel) => {
  //       if (item.country_name === country) {
  //         if (!(item.port_name in portlookup)) {
  //           if (item.port_name !== null) {
  //             portlookup[item.port_name] = 1;
  //             portResult.push(item.port_name);
  //           }
  //         }
  //       }
  //     });
  //     setPortItem(portResult);
  //   }
  // }, [country]);
  const handleRegionChange = (region: string) => {
    setRegion(region);
    setCuntryItem(
      regionDataFilter.find((rgn: any) => rgn.region_name === region).country_name
    );
    setDisableCtry(false);
    setDisablePrt(true);
    setCountry("Country");
    setPort("Port");
  };
  const handleCountryChange = (country: string) => {
    setCountry(country);
    setPortItem(
      countryItem.find((cty: any) => cty.name === country).port_name
    );
    setDisablePrt(false);
    setPort("Port");
  };

  // const pullDropDownItems = () => {
  //   var items = rowData;
  //   //var doctypelookup: any = {};
  //   var busitypelookup: any = {};
  //   var regionlookup: any = {};
  //   var countrylookup: any = {};
  //   var prtlookup: any = {};
  //   //var docTypeResult: string[] = [];
  //   var busiUnitResult: string[] = [];
  //   var regionResult: string[] = [];
  //   var countryResult: string[] = [];
  //   var prtResult: string[] = [];
  //   for (var item, i = 0; (item = items[i++]); ) {
  //     //var doc_type = item.document_type;
  //     var busi_unit = item.bussiness_channel;
  //     var rgn = item.region_name;
  //     var cty = item.country_name;
  //     var prt = item.port_name;
  //     // if (!(doc_type in doctypelookup)) {
  //     //   if (doc_type !== null) {
  //     //     doctypelookup[doc_type] = 1;
  //     //     docTypeResult.push(doc_type);
  //     //   }
  //     // }
  //     if (!(busi_unit in busitypelookup)) {
  //       if (busi_unit !== null) {
  //         busitypelookup[busi_unit] = 1;
  //         busiUnitResult.push(busi_unit);
  //       }
  //     }
  //     if (!(rgn in regionlookup)) {
  //       if (rgn !== null) {
  //         regionlookup[rgn] = 1;
  //         regionResult.push(rgn);
  //       }
  //     }
  //     if (!(cty in countrylookup)) {
  //       if (cty !== null) {
  //         countrylookup[cty] = 1;
  //         countryResult.push(cty);
  //       }
  //     }
  //     if (!(prt in prtlookup)) {
  //       if (prt !== null) {
  //         prtlookup[prt] = 1;
  //         prtResult.push(prt);
  //       }
  //     }
  //   }

  //   //setDocTypeItem(docTypeResult);
  //   setBusiUnitItem(busiUnitResult);
  //   setRegionItem(regionResult);
  //   setCuntryItem(countryResult);
  //   setPortItem(prtResult);
  // };
  const regx = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';.,_/{}()@|\\":<>\?]/);
  const validateAndFilter = () => {
    if(regx.test(nominationNum)) {
      setEnableAgGrid(false);
      setNoDatafound(
        "Please only use numbers and letters in the search field as special characters are not supported."
      );
    } else if (regx.test(invoiceNum)) {
      setEnableAgGrid(false);
      setNoDatafound(
        "Please only use numbers and letters in the search field as special characters are not supported."
      );
    } else filterData();
  }

  const filterData = async () => {
    //setIsLoading(true);
    let filterParamObj: any = {};
    // let filteredData: DrnViewModel[] = [];
    if (businessChannel !== "Business Channel") {
      filterParamObj = {
        ...filterParamObj,
        bussiness_channel: businessChannel,
      };
    }
    if (documentType !== "Document Type") {
      filterParamObj = { ...filterParamObj, document_type: documentType };
    }
    if (nominationNum !== "") {
      filterParamObj = { ...filterParamObj, nomination_number: nominationNum };
    }
    if (invoiceNum !== "") {
      filterParamObj = { ...filterParamObj, invoice_number: invoiceNum };
    }
    if (cusSalesArea !== "") {
      filterParamObj = { ...filterParamObj, country_name: cusSalesArea }; //Customer sales area filter is applied on country column
    }
    if (deliveryDate !== "") {
      filterParamObj = {...filterParamObj, delivery_date: moment(deliveryDate).format('YYYY-MM-DD')};
    }
    if (invoiceDate !== "") {
      filterParamObj = {...filterParamObj, invoice_date: moment(invoiceDate).format('YYYY-MM-DD')};
    }
    if (cusName !== "") {
      filterParamObj = { ...filterParamObj, cust_acct_name: cusName };
    }
    if (region !== "Region") {
      filterParamObj = { ...filterParamObj, region_name: region };
    }
    if (country !== "Country") {
      filterParamObj = { ...filterParamObj, country_name: country };
    }
    if (port !== "Port") {
      filterParamObj = { ...filterParamObj, port_name: port };
    }
   
    if (vessel !== "") {
      filterParamObj = { ...filterParamObj, vessel_name: vessel };
    }
    setFilterQueryParams(filterParamObj);
    deepFilter(filterParamObj,0);   
    // filteredData = nonFilteredRowData.filter(search, filterParamObj);
    // function search(this: any, item: any) {
    //   return Object.keys(this).every(
    //     (key) => item[key] + "".toLowerCase() === this[key] + "".toLowerCase()
    //   );
    // }
    // if (cusName !== "") {
    //   filteredData = filteredData.filter((item) => {
    //     if (
    //       item.cust_acct_name.toLowerCase().indexOf(cusName.toLowerCase()) >= 0
    //     )
    //       return true;
    //     else return false;
    //   });
    // }
    // if (vessel !== "") {
    //   filteredData = filteredData.filter((item) => {
    //     if (item.vessel_name.toLowerCase().indexOf(vessel.toLowerCase()) >= 0)
    //       return true;
    //     else return false;
    //   });
    // }
    // if (deliveryDate !== "") {
    //   filteredData = filteredData.filter((item) => {
    //     return (
    //       moment(item.actl_del_date).format("DD MMM YYYY") === deliveryDate
    //     );
    //   });
    // }
    // if (invoiceDate !== "") {
    //   filteredData = filteredData.filter((item) => {
    //     return moment(item.invoice_date).format("DD MMM YYYY") === invoiceDate;
    //   });
    // }
    // if (result.data.length > 0) {
    //   setRowData(result.data);
    //   //setNoDatafound(false);
    //   //setEnableAgGrid(true);
    // } else {
    //   //setNoDatafound(true);
    //   //setEnableAgGrid(false);
    // }
    // setIsLoading(false);
  };

  const resetFilterInputFields = () => {
    setDocumentType("Document Type");
    setBusinessChannel("Business Channel");
    setNominationNum("");
    setInvoiceNum("");
    setCusSalesArea("");
    setCusName("");
    setVessel("");
    setDeliveryDate("");
    setInvoiceDate("");
    deliveryDateRefComp.current?.flatpickr.clear();
    invoiceDateRefComp.current?.flatpickr.clear();
    //setNoDatafound(false);
    //setEnableAgGrid(true);
    setRegion("Region");
    setCountry("Country");
    setPort("Port");
    if (selectedField === "cust_acct_name" || selectedField === "vessel_name") {
      setDisableCtry(true);
      setDisablePrt(true);
    }
  }

  const clearfilters = () => {
    clearDeepFilter();
    resetFilterInputFields();
    //setRowData(nonFilteredRowData);
   
  };

  const onNominationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNominationNum(e.currentTarget.value);
  };
  const onInvoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceNum(e.currentTarget.value);
  };
  const onCusSlAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCusSalesArea(e.currentTarget.value);
  };
  const onCusNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCusName(e.currentTarget.value);
  };
  const onVesselChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVessel(e.currentTarget.value);
  };
  return (
    <>
      <div className="d-grid gap-4">
        <div className="row">
          <div className="fw-medium filter-text">Filter</div>
        </div>

        {enableDocTypeDropDown && (
          <div className="row">
            <UncontrolledDropdown size="sm">
              <DropdownToggle
                className={
                  documentType === "Document Type"
                    ? "w-100 sch-drpdwn-toggle rounded-0 text-secondary ft-size"
                    : "w-100 sch-drpdwn-toggle rounded-0 ft-size"
                }
                color="#fff"
                caret
              >
                {documentType}
                <Down16
                  color="#007f00 !important"
                  className="btn-icon-suffix"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-sm w-100 ft-size">
                {docTypeItem.map((item, index) => (
                  <div key={item}>
                    <DropdownItem
                      onClick={() => {
                        setDocumentType(item);
                      }}
                    >
                      {item}
                    </DropdownItem>
                    {/* {docTypeItem.length - 1 !== index && (
                      <DropdownItem divider />
                    )} */}
                  </div>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {/* {enableDocTypeDropDown && (
          <div className="row">
            <Input
              className="form-select-placeholder-hack"
              type="select"
              //value={value}
              required
              //onChange={handleChange}
            >
              <option disabled hidden value="">
                Choose an option
              </option>
              <div className="dropdown-menu-sm w-100 shadow-xs dropdown-menu show">
              <option className="dropdown-item" value="option-1">Option 1</option>
              <option className="dropdown-item" value="option-2">Option 2</option>
              <option className="dropdown-item" value="option-3">Option 3</option>
              </div>
              
            </Input>
          </div>
        )} */}
        {enableNominationField && (
          <div className="row">
            <InputGroup size="sm" className="input-group-merge plc-color">
              <Input
                name="nomination"
                placeholder="ERP Order Number"
                className="filter_textfld border-0 ft-size"
                onChange={onNominationChange}
                value={nominationNum}
              />
              <div className="input-group-append">
                <InputGroupText
                  className="filter_textfld border-0"
                  onClick={() => setNominationNum("")}
                >
                  {nominationNum !== "" && (
                    <Remove24 style={{ cursor: "pointer" }} />
                  )}
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        )}
        {enableDeliveryDateField && (
          <div className="row">
            <Datepicker
              placeholder="Delivery Date"
              className="datepicker ft-size"
              ref={deliveryDateRefComp}
              options={{
                onClose: function (selectedDates: [], dateStr: string) {
                  console.log("delivery date", dateStr);
                  console.log("delivery date", selectedDates);
                  setDeliveryDate(dateStr);
                },
                dateFormat: "d M Y",
                altFormat: "d M Y",
                ariaDateFormat: "d M Y",
                disableMobile: true,
                maxDate: new Date(),
              }}
            />
          </div>
        )}
        {enableInvoiceDateField && (
          <div className="row">
            <Datepicker
              placeholder="Invoice Date"
              className="datepicker ft-size"
              ref={invoiceDateRefComp}
              options={{
                onClose: function (selectedDates: [], dateStr: string) {
                  console.log("invoice date", dateStr);
                  console.log("invoice date", selectedDates);
                  setInvoiceDate(dateStr);
                },
                dateFormat: "d M Y",
                altFormat: "d M Y",
                ariaDateFormat: "d M Y",
                disableMobile: true,
                maxDate: new Date(),
              }}
            />
          </div>
        )}
        {enableInvoiceNumField && (
          <div className="row">
            <InputGroup size="sm" className="input-group-merge plc-color">
              <Input
                name="invoice"
                placeholder="Invoice Number"
                className="filter_textfld border-0 ft-size"
                onChange={onInvoiceChange}
                value={invoiceNum}
              />
              <div className="input-group-append">
                <InputGroupText
                  className="filter_textfld border-0"
                  onClick={() => setInvoiceNum("")}
                >
                  {invoiceNum !== "" && (
                    <Remove24 style={{ cursor: "pointer" }} />
                  )}
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        )}
        {enableDocTypeDropDown1 && (
          <div className="row">
            <UncontrolledDropdown size="sm">
              <DropdownToggle
                className={
                  documentType === "Document Type"
                    ? "w-100 sch-drpdwn-toggle rounded-0 text-secondary ft-size"
                    : "w-100 sch-drpdwn-toggle rounded-0 ft-size"
                }
                color="#fff"
                caret
              >
                {documentType}
                <Down16
                  color="#007f00 !important"
                  className="btn-icon-suffix"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-sm w-100 shadow-xs ft-size">
                {docTypeItem.map((item, index) => (
                  <div key={item}>
                    <DropdownItem
                      onClick={() => {
                        setDocumentType(item);
                      }}
                    >
                      {item}
                    </DropdownItem>
                    {/* {docTypeItem.length - 1 !== index && (
                      <DropdownItem divider />
                    )} */}
                  </div>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {enableVesselField && (
          <div className="row">
            <InputGroup size="sm" className="input-group-merge plc-color">
              <Input
                name="vessel"
                placeholder="Vessel"
                className="filter_textfld border-0 ft-size"
                onChange={onVesselChange}
                value={vessel}
              />
              <div className="input-group-append">
                <InputGroupText
                  className="filter_textfld border-0"
                  onClick={() => setVessel("")}
                >
                  {vessel !== "" && <Remove24 style={{ cursor: "pointer" }} />}
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        )}
        {enableCusAccName1 && (
          <div className="row">
            <InputGroup size="sm" className="input-group-merge plc-color">
              <Input
                name="invoice"
                placeholder="Customer Account Name"
                className="filter_textfld border-0 ft-size"
                onChange={onCusNameChange}
                value={cusName}
              />
              <div className="input-group-append">
                <InputGroupText
                  className="filter_textfld border-0"
                  onClick={() => setCusName("")}
                >
                  {cusName !== "" && <Remove24 style={{ cursor: "pointer" }} />}
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        )}
        {enableRegionDropDown && (
          <div className="row">
            <UncontrolledDropdown size="sm">
              <DropdownToggle
                className={
                  region === "Region"
                    ? "w-100 sch-drpdwn-toggle rounded-0 text-secondary ft-size"
                    : "w-100 sch-drpdwn-toggle rounded-0 ft-size"
                }
                color="#fff"
                caret
              >
                {region}
                <Down16
                  color="#007f00 !important"
                  className="btn-icon-suffix"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-sm w-100 shadow-xs ft-size">
                {regionDataFilter.map((item:any, index:number) => (
                  <div key={item.region_name}>
                    <DropdownItem
                      onClick={() => {
                        handleRegionChange(item.region_name);
                      }}
                    >
                      {item.region_name}
                    </DropdownItem>
                    {/* {regionItem.length - 1 !== index && (
                      <DropdownItem divider />
                    )} */}
                  </div>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {enableContyDropDown && (
          <div className="row">
            <UncontrolledDropdown size="sm">
              <DropdownToggle
                className={
                  country === "Country"
                    ? "w-100 sch-drpdwn-toggle rounded-0 text-secondary ft-size"
                    : "w-100 sch-drpdwn-toggle rounded-0 ft-size"
                }
                color="#fff"
                caret
                disabled={disableCtry}
              >
                {country}
                <Down16
                  color="#007f00 !important"
                  className="btn-icon-suffix"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-sm w-100 dropdown-menu-scroll ft-size">
                {countryItem.map((item, index) => (
                  <div key={item.name}>
                    <DropdownItem onClick={() => handleCountryChange(item.name)}>
                      {item.name}
                    </DropdownItem>
                    {/* {countryItem.length - 1 !== index && (
                      <DropdownItem divider />
                    )} */}
                  </div>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {enableRegionDropDown1 && (
          <div className="row">
            <UncontrolledDropdown size="sm">
              <DropdownToggle
                className={
                  region === "Region"
                    ? "w-100 sch-drpdwn-toggle rounded-0 text-secondary ft-size"
                    : "w-100 sch-drpdwn-toggle rounded-0 ft-size"
                }
                color="#fff"
                caret
              >
                {region}
                <Down16
                  color="#007f00 !important"
                  className="btn-icon-suffix"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-sm w-100 ft-size">
                {regionDataFilter.map((item:any, index:number) => (
                  <div key={item.region_name}>
                    <DropdownItem
                      onClick={() => {
                        handleRegionChange(item.region_name);
                      }}
                    >
                      {item.region_name}
                    </DropdownItem>
                    {/* {regionItem.length - 1 !== index && (
                      <DropdownItem divider />
                    )} */}
                  </div>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {enablePortDropDown && (
          <div className="row">
            <UncontrolledDropdown size="sm">
              <DropdownToggle
                className={
                  port === "Port"
                    ? "w-100 sch-drpdwn-toggle rounded-0 text-secondary ft-size"
                    : "w-100 sch-drpdwn-toggle rounded-0 ft-size"
                }
                color="#fff"
                caret
                disabled={disablePrt}
              >
                {port}
                <Down16
                  color="#007f00 !important"
                  className="btn-icon-suffix"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-sm w-100 dropdown-menu-scroll ft-size">
                {portItem.map((item, index) => (
                  <div key={item}>
                    <DropdownItem onClick={() => setPort(item)}>
                      {item}
                    </DropdownItem>
                    {/* {portItem.length - 1 !== index && <DropdownItem divider />} */}
                  </div>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {enableBusiCnlDropDown && (
          <div className="row">
            <UncontrolledDropdown size="sm">
              <DropdownToggle
                className={
                  businessChannel === "Business Channel"
                    ? "w-100 sch-drpdwn-toggle rounded-0 text-secondary ft-size"
                    : "w-100 sch-drpdwn-toggle rounded-0 ft-size"
                }
                color="#fff"
                caret
              >
                {businessChannel}
                <Down16
                  color="#007f00 !important"
                  className="btn-icon-suffix"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-sm w-100 ft-size">
                {busiUnitItem.map((item) => (
                  <div key={item}>
                    <DropdownItem
                      onClick={() => {
                        setBusinessChannel(item);
                      }}
                    >
                      {item}
                    </DropdownItem>
                    {/* {busiUnitItem.length - 1 !== index && (
                      <DropdownItem divider />
                    )} */}
                  </div>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {enableCusSalArea && (
          <div className="row">
            <InputGroup size="sm" className="input-group-merge plc-color">
              <Input
                name="invoice"
                placeholder="Customer Sales Area"
                className="filter_textfld border-0 ft-size"
                onChange={onCusSlAreaChange}
                value={cusSalesArea}
              />
              <div className="input-group-append">
                <InputGroupText
                  className="filter_textfld border-0"
                  onClick={() => setCusSalesArea("")}
                >
                  {cusSalesArea !== "" && (
                    <Remove24 style={{ cursor: "pointer" }} />
                  )}
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        )}
        {enableCusAccName && (
          <div className="row">
            <InputGroup size="sm" className="input-group-merge plc-color">
              <Input
                name="invoice"
                placeholder="Customer Account Name"
                className="filter_textfld border-0 ft-size"
                onChange={onCusNameChange}
                value={cusName}
              />
              <div className="input-group-append">
                <InputGroupText
                  className="filter_textfld border-0"
                  onClick={() => setCusName("")}
                >
                  {cusName !== "" && <Remove24 style={{ cursor: "pointer" }} />}
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        )}
      </div>
      <div
        className="d-flex justify-content-between filter-buttom-margin"
        style={{ marginTop: "1.5rem" }}
      >
        <Button
          level="secondary"
          rounded="pill"
          size="sm"
          theme="brand"
          className="clear-button ft-size"
          onClick={() => clearfilters()}
        >
          Clear
        </Button>
        <Button
          level="primary"
          rounded="pill"
          size="sm"
          theme="brand"
          className="search-button ft-size"
          onClick={() => {
            validateAndFilter();
            setTotalRecordsCount(0);
          }}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
