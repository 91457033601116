import moment from "moment";
import { RecordValidModel } from "../../model/RecordValidModel";
import { useWindowSize } from "@bphxd/ds-core-react";

export default function RecordsValidation({
  validDrn,
}: {
  validDrn: RecordValidModel[];
}) {
  const { width } = useWindowSize();

  return (
    <>
      {width < 576 ? (
        <div className="container record-container rounded-4 bg-bp-dark-green-700 mb-5 mx-0 mw-100">
          <div className="row">
            <div className="text-white fw-normal con-eles col-lg-2 col-6">
              <div className="prop-font">Order Number</div>
              <div className="value-font">{validDrn[0].order_number}</div>
            </div>
            <div className="text-white fw-normal con-eles col-lg-2 col-6 txt-right">
              <div className="prop-font">Customer Name</div>
              <div className="value-font">{validDrn[0].cust_name}</div>
            </div>
            <div className="text-white fw-normal con-eles col-lg-1 col-6 left-pding-region">
              <div className="prop-font">Region</div>
              <div className="value-font">{validDrn[0].region_name}</div>
            </div>
            <div className="text-white fw-normal con-eles col-lg-2 col-6 left-pding-country txt-right">
              <div className="prop-font">Country</div>
              <div className="value-font">{validDrn[0].country_name}</div>
            </div>
            <div className="text-white fw-normal con-eles col-lg-1 col-6">
              <div className="prop-font">Port</div>
              <div className="value-font">{validDrn[0].port_name}</div>
            </div>
            <div className="text-white fw-normal con-eles col-lg-2 col-6 left-pding-vessel txt-right">
              <div className="prop-font">Vessel</div>
              <div className="value-font">{validDrn[0].vessel_name}</div>
            </div>
            <div className="text-white fw-normal con-eles col-lg-2 col-6">
              <div className="prop-font">Delivery Date</div>
              <div className="value-font">
              {validDrn[0].actl_del_date ? moment(validDrn[0].actl_del_date).format("DD-MMM-YYYY") : ''}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between record-container rounded-4 bg-bp-dark-green-700 mb-5">
          <div className="text-white fw-normal lh-sm">
            <div style={{ fontSize: "1.1rem" }}>Order Number</div>
            <div className="fs-6">{validDrn[0].order_number}</div>
          </div>
          <div className="text-white fw-normal lh-sm">
            <div style={{ fontSize: "1.1rem" }}>Customer Name</div>
            <div className="fs-6">{validDrn[0].cust_name}</div>
          </div>
          <div className="text-white fw-normal lh-sm">
            <div style={{ fontSize: "1.1rem" }}>Region</div>
            <div className="fs-6">{validDrn[0].region_name}</div>
          </div>
          <div className="text-white fw-normal lh-sm">
            <div style={{ fontSize: "1.1rem" }}>Country</div>
            <div className="fs-6">{validDrn[0].country_name}</div>
          </div>
          <div className="text-white fw-normal lh-sm">
            <div style={{ fontSize: "1.1rem" }}>Port</div>
            <div className="fs-6">{validDrn[0].port_name}</div>
          </div>
          <div className="text-white fw-normal lh-sm">
            <div style={{ fontSize: "1.1rem" }}>Vessel</div>
            <div className="fs-6">{validDrn[0].vessel_name}</div>
          </div>
          <div className="text-white fw-normal lh-sm">
            <div style={{ fontSize: "1.1rem" }}>Delivery Date</div>
            <div className="fs-6">
              {validDrn[0].actl_del_date !== null
                ? moment(validDrn[0].actl_del_date).format("DD-MMM-YYYY")
                : ""}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
