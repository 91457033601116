import "./App.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { config } from "../../../src/config";
import { MsalProvider } from "@azure/msal-react";
import AppLayout from "../AppLayout";
import QuickSearch from "../Home/Home";
import Login from "../../pages/Login";
import Logout from "../../pages/Logout";
import DRNUpload from "../DRNUpload/DRNUpload";
import MainHeader from "../PublicComponents/MainHeader";
import UnAuth from "../PublicComponents/UnAuth";
import { UserContextProvider } from "../../contexts/userContextApi";
import AdvancedSearch from "../AdvancedSearch/AdvancedSearch";

function App() {
  const pca = new PublicClientApplication(config);

  return (
    <MsalProvider instance={pca}>
        <UserContextProvider>
          <BrowserRouter>
            <MainHeader />
            <Routes>
              <Route path="/" element={<Login />} />
              {/*nav routes*/}
              <Route path="/drn" element={<AppLayout />}>
                <Route index element={<Navigate to="quickSearch" replace />} />
                <Route path="quickSearch" element={<QuickSearch />} />
                <Route path="uploadDrn" element={<DRNUpload />} />
                <Route path="advancedSearch" element={<AdvancedSearch />} />
                <Route path="logout" element={<Logout />} />
              </Route>
              <Route path="/UnAuthorize" element={<UnAuth />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </UserContextProvider>
    </MsalProvider>
  );
}
export default App;
