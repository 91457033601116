import { Navbar, Nav, NavItem } from "reactstrap";
//import "./Header.scss";
import { NavLink } from "react-router-dom";

export default function Header() {
  const isAdmin = sessionStorage.getItem("isAdmin");
  return (
    // <div className="header">
    //   <div className="nav-item-container me-auto">
    //     <NavLink to="quickSearch" className="nav-item">
    //       Quick Search
    //     </NavLink>
    //     {/* <NavLink
    //     to='drnSearch'
    //     className='nav-item'
    //   >
    //     DRN Search
    //   </NavLink>
    //   <NavLink
    //     to='invoiceSearch'
    //     className='nav-item'
    //   >
    //     Invoice Search
    //   </NavLink>
    //   <NavLink
    //     to='orphansSearch'
    //     className='nav-item'
    //   >
    //     Orphans Search
    //   </NavLink>
    //   <NavLink
    //     to='perfomanceReport'
    //     className='nav-item'
    //   >
    //     Perfomance Report
    //   </NavLink> */}
    //     {isAdmin === "true" && (
    //       <NavLink to="uploadDrn" className="nav-item">
    //         Upload DRN
    //       </NavLink>
    //     )}
    //   </div>
    // </div>
    <>
      <Navbar
        className="navbar-border d-lg-flex justify-content-center navbar-tabs navbar-lg bg-tertiary"
        expand
      >
        <Nav className="d-lg-flex flex-row" navbar>
          <NavItem>
            <NavLink className="nav-link" to="quickSearch">
              <span>Quick Search</span>
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink className="nav-link" to="advancedSearch">
              <span>Advanced Search</span>
            </NavLink>
          </NavItem> */}
          {isAdmin === "true" && (
            <NavItem>
              <NavLink className="nav-link" to="uploadDrn">
                <span>Document Upload </span>
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Navbar>
    </>
  );
}
