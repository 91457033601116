import axios from "axios";
import { Buffer } from 'buffer';
import { drnEndpoint, downloadDocuments, previewDocument } from "../endpoints";

// export const downloadSelectedDrn1 = async (docIdArr: string[], token: any) => {
//   try {
//     const resp = await axios.post(
//       `${downloadDocuments}`,
//       docIdArr,
//       {
//         method: "post",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return resp.data;
//   } catch (ex) {
//     throw ex;
//   }
// };

export const downloadSelectedDrn = async (docIdArr: any, token: any) => {
  await axios.post(`${downloadDocuments}`, docIdArr, {
    headers: {
      "Content-Type": "application/json",
      Authorizationtoken: `Bearer ${token}`,
    }
  }).then(
    (res) => {
          // const buffer = Buffer.from(res.data.data, 'base64');
          // const blob = new Blob([buffer], { type: 'application/octet-stream' });
          const downloadUrl = res.data.data;
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = 'documents.zip';
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(downloadUrl);
          return true;
    }
  )
  //window.open(downloadUrl);
};

export const downloadSingle = async (docIdArr: any, document_id: any, order_number: any, document_type: any, token: any) => {
  await axios.post(`${downloadDocuments}`, docIdArr, {
    headers: {
      "Content-Type": "application/json",
      Authorizationtoken: `Bearer ${token}`,
    }
  }).then(
    (res) => {
          const buffer = Buffer.from(res.data.data, 'base64');
          const extension = res.data.fileType.split("/")[1];
          const blob = new Blob([buffer], { type: 'application/octet-stream' });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = `${document_id} - ${order_number} - ${document_type}.${extension}`;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(downloadUrl);
          return true;
    }
  )
  //  window.open(downloadUrl);
};

export const viewDrn = async (url: string, token: any) => {
  try {
    const res = await axios.post(previewDocument ,{document_link : url},{
      headers: {
        "Content-Type": "application/json",
        Authorizationtoken: `Bearer ${token}`,
      }
    });
    return res.data;
  } catch(ex) {
    throw ex;
  } 
};
// export const viewDrn = async (url: string, token: any) => {
//   try {
//     const res = await axios.get("http://localhost:3000/mock/drn_preview_res.json",{
//       headers: {
//         "Content-Type": "application/json",
//         Authorizationtoken: `Bearer ${token}`,
//       }
//     });
//     console.log(res.data.document_link);
//     return res.data.document_link;
//   } catch(ex) {
//     throw ex;
//   } 
// };
