import {
    Button,
  } from 'reactstrap';
  import { IPublicClientApplication } from '@azure/msal-browser';
  import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorize } from '../../api/Auth/authorization';
import { useUser } from '../../contexts/userContextApi';

export default function UnAuth(){
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { setIsAuthorize, setIsAdmin, setIsLoadingLandingPage } = useUser();
  const [isUnAuthorizePageLoaded, setIsUnAuthorizePageLoaded] = useState<boolean>(false);
  const fetchAuthToken = async (token: any) => {
    setIsLoadingLandingPage(true);
    setIsUnAuthorizePageLoaded(false);
    let res:any = await authorize(token).finally(() => {
      setIsLoadingLandingPage(false);
      setIsUnAuthorizePageLoaded(true);
    }
    );
    sessionStorage.setItem("jwt", res.jwtToken);
    sessionStorage.setItem("isAdmin", res.isAdmin);
    setIsAdmin(res.isAdmin);
    return res;
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log(isAuthenticated, "isAuthenticated-------");

      const accessTokenRequest = {
        account: accounts[0],
        scopes: ["User.Read"],
      };
      const token = accessTokenRequest.account.idToken;
      let res = fetchAuthToken(token);
      res.then((res) => {
        if (
          res ===
          "User is not authorized to access this resource with an explicit deny"
        ) {
          //redirectSignOut(instance);
          sessionStorage.setItem("isUnAuth", "false");
          setIsAuthorize(false);
          navigate("/UnAuthorize");
        } else {
          sessionStorage.setItem("isUnAuth", "true");
          setIsAuthorize(true);
          navigate("/drn");
        }
      });
    } else {
      navigate("/");
    }
  }, [isAuthenticated]);
    const redirectSignOut = (instance: IPublicClientApplication) => {
        const logoutRequest = {
          postLogoutRedirectUri: `https://dtztk57qeqd06.cloudfront.net/`
        };
      
        instance.logoutRedirect(logoutRequest);
      };
    return(
        
        <> 
        {isUnAuthorizePageLoaded && <div style={{textAlign:"center"}}>You do not have the necessary permissions.  <div>Reach out to your administrator for access. </div></div>}
        </>
    )
}