import { Avatar, Spinner } from '@bphxd/ds-core-react'
import { UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap'
import './MainHeader.scss'
import { LogOut24 } from '@bphxd/ds-core-react/lib/icons'
import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useUser } from '../../contexts/userContextApi';
export default function MainHeader() {
    const [userName, setUserName] = useState<any>('User');
    const {isAuthorize,isAdmin,isLoadingLandingPage} = useUser();
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    //const isAdmin = sessionStorage.getItem("isAdmin");
    //let auth = sessionStorage.getItem("isUnAuth");

    useEffect(() => {
        const timer = setTimeout(() => {
            sessionStorage.clear();
            redirectSignOut(instance);
          console.log("Session expired!!")
        }, 1000 * 60 * 59);
        return () => clearTimeout(timer);
      }, []);

    useEffect(()=>{
        if (isAuthenticated) {
            if(accounts[0].idTokenClaims?.name?.length !== 0 || accounts[0].idTokenClaims?.name !== undefined) 
            {
                setUserName(accounts[0].idTokenClaims?.name);
            }
        }
        else{
            setUserName('User');
        }
    },[isAuthenticated, accounts]);

    const redirectSignOut = (instance: IPublicClientApplication) => {
        const logoutRequest = {
            postLogoutRedirectUri: `https://gmedocumenthub.castrol.com/`
        };
        sessionStorage.clear();
        instance.logoutRedirect(logoutRequest);
    }; 
    return (
        <div>
            <div style={{ display: 'block' }} className=''>
                <div className='main-header-logo-background main-header-logo'>
                    <img
                        className='main-header-logo-img'
                        width="100%"
                        height="100%"
                        src='/images/castrol_logo.svg'
                        alt='Castrol Logo'
                    />
                    <div className='main-header-text'>IT'S MORE THAN JUST OIL. IT'S LIQUID ENGINEERING.</div>
                    {isAuthorize && <AuthenticatedTemplate>
                        <UncontrolledDropdown className="d-inline-flex ms-6 profile-field">
                            <Avatar size='sm' className="me-3" label="My Account" suffix dropdown />
                            <DropdownMenu end>
                                <DropdownItem disabled>
                                    <span>Welcome{' '}{userName}</span>
                                </DropdownItem>
                                <DropdownItem disabled>
                                    <span>Logged in as {isAdmin  ? 'Admin': 'User'}</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => redirectSignOut(instance)}>
                                    <span><LogOut24 className='mx-3' color='#007f00' />Sign Out</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </AuthenticatedTemplate>}
                </div>
            </div>
            {
                isLoadingLandingPage && (
                    <div className="landing-loader">
                      <Spinner color="brand" />
                    </div>
                )
            }
        </div>


    )
}