import { ReactTable } from "@bphxd/ds-core-react";
import { Download24, Kebab24, Preview24 } from "@bphxd/ds-core-react/lib/icons";
import { useMsal } from "@azure/msal-react";
import { RowSelectionState, ColumnDef } from "@tanstack/react-table";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { downloadSingle, viewDrn } from "../../api/DRN/drndownload";
import { DrnViewModel } from "../../model/DrnViewModel";
import moment from "moment";

export default function DataTable({
  rowData,
  setSelectedFlatRowsVals,
}: {
  rowData: DrnViewModel[];
  setSelectedFlatRowsVals: Dispatch<SetStateAction<DrnViewModel[]>>;
}) {
  const [selectedFlatRows, setSelectedFlatRows] = useState<DrnViewModel[]>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [randomKeyTable, setRandomKeyTable] = useState<number>(0);
  const { instance, accounts } = useMsal();
  const accessTokenRequest = {
    account: accounts[0],
    scopes: ["User.Read"],
  };
  // const token = accessTokenRequest.account.idToken;

  const token = sessionStorage.getItem("jwt");
  useEffect(() => {
    setSelectedFlatRowsVals(selectedFlatRows);
  }, [selectedFlatRows]);

  useEffect(() => {
    setRowSelection({});
    setRandomKeyTable(Math.floor(Math.random() * 100));
  }, [rowData]);

  function downloadPdfs(document: {
    document_type: any;
    document_id: any;
    order_number: any;
  }) {
    let docArray = [];
    let documentobj = Object();
    documentobj.document_id = document.document_id;
    documentobj.document_type = document.document_type;
    docArray.push(documentobj);
    console.log(document);
    downloadSingle(
      docArray,
      document.document_id,
      document.order_number,
      document.document_type,
      token
    );
  }
  const preView = async (url:string) => {
    try {
      let previewUrl:any = await viewDrn(url,token);
      window.open(previewUrl, "_blank", "width=700,height=700");
    } catch(ex) {
      console.log(ex);
    }
  }

  const cols: {
    accessorKey: string;
    header: any;
    cell?: any;
    sortingFn?: any;
  }[] = [
    {
      accessorKey: "document_type",
      header: "Document Type",
      cell: (props: any) => {
        return <div className="ms-3">{props.getValue()}</div>;
      },
    },
    {
      accessorKey: "document_id",
      header: "Document ID",
      sortingFn: "alphanumeric",
    },
    {
      accessorKey: "upload_date",
      header: "Uploaded Date",
      cell: (props: any) => {
        return (
          <div className="invDateCell">
            {props.getValue() !== null
              ? moment(props.getValue()).format("DD MMM YYYY").replaceAll("01 Jan 1900","")
              : ""}
          </div>
        );
      },
    },
    {
      accessorKey: "order_number",
      header: "Order Number",
      sortingFn: "alphanumeric",
      cell: (props: any) => {
        return (
          <div style={{ whiteSpace:"pre-line" }}>
            {props.getValue()
              ? props.getValue().replace(/["{}]/g, "").replace(/[,]/g, "\n").replaceAll("NULL","")
              : ""}
          </div>
        );
      },
    },
    {
      accessorKey: "nomination_number",
      header: "ERP Order Number",
      sortingFn: "alphanumeric",
      cell: (props: any) => {
        return (
            <>
            {props.getValue()
              ? props.getValue().replace(/[{}"]/g, "").replace(/[,]/g, "\n")
              : ""}
            </>
        );
      },
    },
    {
      accessorKey: "invoice_number",
      header: "Invoice Number",
      sortingFn: "alphanumeric",
      cell: (props: any) => {
        return (
          <>
            {props.getValue()
              ? props
                  .getValue()
                  .replaceAll("No number yet","")
                  .replace(/[^,a-zA-Z0-9 ]/g, "")
                  .replace(/[,]/g, "\n")
              : ""}
          </>
        );
      },
    },
    {
      accessorKey: "invoice_date",
      header: "Invoice Date",
      cell: (props: any) => {
        return (
          <div className="invDateCell">
            {props.getValue() !== null ? props.getValue().replaceAll("01 Jan 1900","") : ""}
          </div>
        );
      },
    },
    {
      accessorKey: "actl_del_date",
      header: "Delivery Date",
      cell: (props: any) => {
        return (
          <div className="invDateCell">
            {props.getValue() !== null
              ? moment(props.getValue()).format("DD MMM YYYY").replaceAll("01 Jan 1900","")
              : ""}
          </div>
        );
      },
    },
    {
      accessorKey: "port_name",
      header: "Port",
      cell: (props: any) => {
        return (
          <div style={{ minWidth: "70px", whiteSpace:"pre-line" }}>
            {props.getValue()
              ? props.getValue().replace(/["{}]/g, "").replace(/[,]/g, "\n").replaceAll("NULL","")
              : ""}
          </div>
        );
      },
    },
    {
      accessorKey: "cust_acct_name",
      header: "Customer Name",
      cell: (props: any) => {
        return (
          <div style={{ minWidth: "70px", whiteSpace:"pre-line" }}>
            {props.getValue()
              ? props.getValue().replace(/["{}]/g, "").replace(/[,]/g, "\n").replaceAll("NULL","")
              : ""}
          </div>
        );
      },
    },
    {
      accessorKey: "vessel_name",
      header: "Vessel",
      cell: (props: any) => {
        return (
          <div style={{ minWidth: "70px", whiteSpace:"pre-line" }}>
            {props.getValue()
              ? props.getValue().replace(/["{}]/g, "").replace(/[,]/g, "\n").replaceAll("NULL","")
              : ""}
          </div>
        );
      },
    },
    {
      accessorKey: "country_name",
      header: "Country",
      cell: (props: any) => {
        return (
          <div style={{ minWidth: "70px", whiteSpace:"pre-line" }}>
            {props.getValue()
              ? props.getValue().replace(/["{}]/g, "").replace(/[,]/g, "\n").replaceAll("NULL","")
              : ""}
          </div>
        );
      },
    },
    {
      accessorKey: "region_name",
      header: "Region",
      cell: (props: any) => {
        return (
          <div className="BusiChlcell">
            {props.getValue()
              ? props.getValue().replace(/["{}]/g, "").replace(/[,]/g, "\n").replaceAll("NULL","")
              : ""}
            <UncontrolledDropdown size="sm">
              <DropdownToggle>
                <Kebab24 />
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-menu-sm shadow-xs"
                style={{ width: "50px" }}
                end
              >
                <DropdownItem
                  onClick={() => {
                    preView(props.row.original.document_link);
                  }}
                  disabled={
                    props.row.original.document_link === null ? true : false
                  }
                >
                  <Preview24 />
                  &nbsp;&nbsp;View
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    downloadPdfs(props.row.original);
                  }}
                  disabled={
                    props.row.original.document_link === null ? true : false
                  }
                >
                  <Download24 />
                  &nbsp;&nbsp;Download
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        );
      },
    },
  ];
  const columns = useMemo<ColumnDef<DrnViewModel>[]>(() => cols, []);
  //const data = useMemo<DrnModel[]>(() => rowData, []);
  return (
    <>
      <ReactTable<DrnViewModel>
        tableClassName="table-main-class"
        columns={columns}
        data={rowData}
        striped={true}
        responsive={true}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setSelectedFlatRows={setSelectedFlatRows}
        key={randomKeyTable}
      />
    </>
  );
}
