const NEXT_API_ENDPOINT='https://api3bkkc76.execute-api.eu-west-1.amazonaws.com/prod'

// https://3mfe4yt7xd.execute-api.eu-west-1.amazonaws.com/dev/api/searchOrder?searchField=order_number&searchVal=11509334432

export const baseEndpoint = `${NEXT_API_ENDPOINT}/api`;

// DRN search
export const drnEndpoint = `${baseEndpoint}/searchOrder`;

// Regions load
export const regionEndpoint = `${baseEndpoint}/getRegion`;

// Region Search
export const regionSearch = `${baseEndpoint}/searchByRegion`

// upload DRN
export const uploadDrn = `${baseEndpoint}/uploadDrnData`;

// upload search
export const uploadSearch = `${baseEndpoint}/getOrderDetails`

// download Documents
export const downloadDocuments = `${baseEndpoint}/downloadInvoice`

//authorization
export const authorization = `${baseEndpoint}/getRole`;

// delete Documents
export const deleteDocuments = `${baseEndpoint}/deleteDocument`;

//Preview Document
export const previewDocument = `${baseEndpoint}/previewDocument`