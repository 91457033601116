import { DrnFile } from '../model/DrnFile';
import { Order } from '../model/Order';
import {
  OrderActionTypes,
  REMOVE_ERROR,
  REMOVE_SUCCESS_MESSAGE,
  SAVE_DRN_ERROR,
  SAVE_DRN_SUCCESS,
  SAVE_ORDER,
  SEARCH_STARTED,
} from './actions';

//TODO - Name interface properly as this includes Order +Drn
export interface OrderState {
  order: Order;
  drnfiles: DrnFile[];
  loading: boolean;
  loaded: boolean;
  error: null | string;
  success_message: null | string;
}

export const initialState = {
  order: null,
  drnfiles: [],
  loading: false,
  loaded: false,
  error: null,
  success_message: null,
};

export const orderReducer = (
  state: OrderState,
  action: { type: OrderActionTypes; payload: null | any }
) => {
  switch (action.type) {
    case SAVE_ORDER:
      return {
        ...state,
        order: action.payload.order,
        drnfiles: action.payload.drnfiles || [],
        loading: false,
        loaded: true,
        error: null,
      };
    case SEARCH_STARTED:
      return {
        ...state,
        order: null,
        loading: false,
        loaded: true,
        error: null,
      };
    case SAVE_DRN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        success_message: action.payload,
      };
    case SAVE_DRN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: true,
      };
    case REMOVE_ERROR:
      return {
        ...state,
        error: null,
      };
    case REMOVE_SUCCESS_MESSAGE:
      return {
        ...state,
        success_message: null,
      };
    default:
      return state;
  }
};
