export const SAVE_ORDER = 'SAVE ORDER';
export const SEARCH_STARTED = 'SEARCH STARTED';
export const SAVE_DRN = 'SAVE DRN';
export const SAVE_DRN_SUCCESS = 'SAVE DRN SUCCESS';
export const SAVE_DRN_ERROR = 'SAVE DRN ERROR';
export const REMOVE_ERROR = 'REMOVE ERROR';
export const REMOVE_SUCCESS_MESSAGE = 'REMOVE SUCCESS MESSAGE';

/**
 * Action Types
 */
export type SAVE_ORDER_TYPE = 'SAVE ORDER';
export type SEARCH_STARTED_TYPE = 'SEARCH STARTED';
export type SAVE_DRN_TYPE = 'SAVE DRN';
export type SAVE_DRN_SUCCESS_TYPE = 'SAVE DRN SUCCESS';
export type SAVE_DRN_ERROR_TYPE = 'SAVE DRN ERROR';
export type REMOVE_ERROR_TYPE = 'REMOVE ERROR';
export type REMOVE_SUCCESS_MESSAGE_TYPE = 'REMOVE SUCCESS MESSAGE';
export type OrderActionTypes =
  | SAVE_ORDER_TYPE
  | SEARCH_STARTED_TYPE
  | SAVE_DRN_TYPE
  | SAVE_DRN_SUCCESS_TYPE
  | SAVE_DRN_ERROR_TYPE
  | REMOVE_ERROR_TYPE
  | REMOVE_SUCCESS_MESSAGE_TYPE;
