import {
  Button,
} from 'reactstrap';
import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';

const Logout: React.FC = () => {
  const { instance, accounts } = useMsal();

const redirectSignOut = (instance: IPublicClientApplication) => {
  const logoutRequest = {
    postLogoutRedirectUri: `https://gmedocumenthub.castrol.com/`
  };
  sessionStorage.clear();

  instance.logoutRedirect(logoutRequest);
};
  return (
      <div className="app">
          <Button onClick={() => redirectSignOut(instance)}>
          Sign out
        </Button>
      </div>
  )  
}

export default Logout;