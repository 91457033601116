import { useMsal } from "@azure/msal-react";
import "./DRNUpload.scss";
import { useEffect, useState } from "react";
import { uploadRecords } from "../../api/UploadDRN/uploaddrn";
import UploadSearch from "./UploadSearch";
import RecordsValidation from "./RecordsValidation";
import { fetchValidationSearch } from "../../api/UploadDRN/validationsearch";
import { RecordValidModel } from "../../model/RecordValidModel";
import { Spinner } from "@bphxd/ds-core-react";
import UploadForm from "./UploadForm";
import moment from "moment";
export default function DRNUpload() {
  const { instance, accounts } = useMsal();
  const accessTokenRequest = {
    account: accounts[0],
    scopes: ["User.Read"],
  };
  // const token = accessTokenRequest.account.idToken;
  const token = sessionStorage.getItem("jwt");
  const userEmail = accessTokenRequest.account.username;

  const [validDrn, setValidDrn] = useState<RecordValidModel[]>([]);
  const [docTypes, setDocTypes] = useState([]);
  const [allDocTypes, setAllDocTypes] = useState([]);
  const [filecount, setFilecount] = useState<null | string>("");
  const [noData, setNoData] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [srhField, setSrhField] = useState<string>("");

  const onDrnUpdSearch = async (searchField: string, searchVal: string) => {
    setSrhField(searchField);
    setIsLoading(true);
    setNoData("");
    setValidDrn([]);
    searchField =
      searchField === "Order Number"
        ? "order_number"
        : searchField === "ERP Order Number"
        ? "nomination_number"
        : "order_number";

    let resData: any = [];
    try {
      resData = await fetchValidationSearch(
        searchField,
        searchVal.trim(),
        token
      ).finally(() => setIsLoading(false));
      if (resData === "no records found") {
        setNoData("No data found! Please check search/filter parameters");
        setValidDrn([]);
      } 
      else if(resData.statusCode === 422) {
        setNoData("No data found! Please check search/filter parameters");
        setValidDrn([]);
      }
      else {
        let modifiedData = resData.data.map((ele: any) => {
          return {
            ...ele,
            actl_del_date:
              (ele.actl_del_date !== null || ele.actl_del_date !== "")
                ? moment(moment(ele.actl_del_date).format("YYYY-MM-DD")).isSame("1900-01-01")
                  ? null
                  : ele.actl_del_date
                : null,
          };
        });
        setValidDrn(modifiedData);
      }
      setDocTypes(resData.docType);
      setFilecount(resData.fileCount);
      setAllDocTypes(resData.allDocType);
    } catch {
      setNoData("No data found! Please check search/filter parameters");
      setValidDrn([]);
      //no record found
    } finally {
      console.log(validDrn);
    }
  };
  const onUploadSubmit = async (upData: any) => {
    return new Promise(async (resolve) => {
      try {
        let res = await uploadRecords(upData, token);
        if (res === "upload success 200") {
          setValidDrn([]);
          setNoData("Records Uploaded Successfully");
        } else if (
          res ===
          "Unfortunately, the portal does not allow more than one GR number in the document."
        ) {
          setValidDrn([]);
          setNoData(
            "Unfortunately, the portal does not allow more than one GR number in the document."
          );
        } else if (
          res ===
          "Unfortunately, the searched GR number does not appear in the document."
        ) {
          setValidDrn([]);
          setNoData(
            "Unfortunately, the searched GR number does not appear in the document."
          );
        } else if (
          res === "Unfortunately, the uploaded file format doesn't acceptable."
        ) {
          setValidDrn([]);
          setNoData(
            "Unfortunately, the uploaded file format doesn't acceptable."
          );
        }
      } catch (error) {
        setValidDrn([]);
        setNoData("Server Error! Unable to Upload");
      } finally {
        resolve("off-loader");
      }
    });
  };
  return (
    <div className="App bg-secondary">
      <UploadSearch
        onDrnUpdSearch={onDrnUpdSearch}
        setNoData={setNoData}
        setValidDrn={setValidDrn}
      />
      {isLoading && (
        <div className="table-loader">
          <Spinner color="brand" />
        </div>
      )}

      {validDrn.length > 0 ? (
        <>
          <RecordsValidation validDrn={validDrn} />
          <UploadForm
            validDrn={validDrn}
            onUploadSubmit={onUploadSubmit}
            userEmail={userEmail}
            setIsLoading={setIsLoading}
            docTypes={docTypes}
            filecount={filecount}
            allDocTypes={allDocTypes}
            srhField={srhField}
          />
        </>
      ) : (
        <div style={{ height: "300px", width: "100%", textAlign: "center" }}>
          {noData}
        </div>
      )}
    </div>
  );
}
