import { Down24, Remove24 } from "@bphxd/ds-core-react/lib/icons";
import SvgSearch24 from "@bphxd/ds-core-react/lib/icons/interface/Search24";
import { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input,
  InputGroupText,
} from "reactstrap";
import { Button } from "@bphxd/ds-core-react/lib/components/button/Button";
import { useWindowSize } from "@bphxd/ds-core-react";
import { RecordValidModel } from "../../model/RecordValidModel";

export default function UploadSearch({
  onDrnUpdSearch,
  setNoData,
  setValidDrn,
}: {
  onDrnUpdSearch: (searchField: string, searchVal: string) => void;
  setNoData: React.Dispatch<React.SetStateAction<string>>;
  setValidDrn:  React.Dispatch<React.SetStateAction<RecordValidModel[]>>
}) {
  const [searchField, setsearchField] = useState<string>("Select Category");
  const [searchText, setSearchText] = useState<string>("");
  const { width } = useWindowSize();

  useEffect(() => {
    setNoData("");
    setSearchText("");
  }, [searchField]);

  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
    setNoData("");
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      if (searchField !== "Select Category" && searchText !== "") {
        event.preventDefault();
        event.stopPropagation();
        //onDrnUpdSearch(searchField, searchText);
        validateAndSearch();
      }
    }
  };
  const regx = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';.,_/{}()@|\\":<>\?]/);
  const validateAndSearch = () => {
    setValidDrn([]);
    if (regx.test(searchText)) {
      setNoData("Please only use numbers and letters in the search field as special characters are not supported");
    } else {
      if (searchField === "UOA (GR) Number") {
        if (searchText.slice(0, 2).toUpperCase() === "GR") {
          onDrnUpdSearch(searchField, searchText);
        } else {
          setNoData("Please Enter a Valid UOA (GR) Number");
        }
      } else if (searchField === "ERP Order Number") {
        if (searchText.slice(0, 2).toUpperCase() === "GR") {
          setNoData("Please Enter a Valid ERP Order Number");
        } else {
          onDrnUpdSearch(searchField, searchText);
        }
      } else {
        if (searchText.slice(0, 2).toUpperCase() === "GR") {
          setNoData("Please Enter a Valid Order Number");
        } else {
          onDrnUpdSearch(searchField, searchText);
        }
      }
    }
  };
  return (
    <>
      <div className="row">
        {/* <div className="col-sm-2 mb-3" style={{ fontSize: "0.8125rem" }}>
          Select Category
        </div> */}
      </div>
      <div className="row">
        <div className="col-sm-3 col-lg-2 upd-srch-drop">
          <UncontrolledDropdown size="sm" className="mb-4">
            <DropdownToggle
              className="w-100 sch-drpdwn-toggle-uld rounded-0 border-0 px-0 ft-size"
              color="#fff"
              caret
            >
              {searchField}
              <Down24
                color="#007f00 !important"
                className="btn-icon-suffix down-arrow-color"
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-sm w-100 ft-size">
              <DropdownItem
                onClick={() => {
                  setsearchField("Order Number");
                }}
              >
                Order Number
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("ERP Order Number");
                }}
              >
                ERP Order Number
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setsearchField("UOA (GR) Number");
                }}
              >
                UOA (GR) Number
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-sm-7 col-lg-5">
          <InputGroup className="input-group-merge mb-4 search-input" size="sm">
            <Input
              className="form-control-prepended form-control-appended ft-size"
              placeholder={
                searchField === "Select Category"
                  ? "Search"
                  : searchField === "Order Number" && width > 576
                  ? "Search with Manta Order number or New Marine Order number"
                  : `Search with ${searchField}`
              }
              type="text"
              onChange={onSearchTextChange}
              onKeyDown={onKeyDown}
              value={searchText}
            />
            <div className="input-group-prepend">
              <InputGroupText>
                <SvgSearch24 color="#007f00" />
              </InputGroupText>
            </div>
            <div className="input-group-append">
              <InputGroupText
                onClick={() => {
                  setSearchText("");
                  setNoData("");
                }}
              >
                {searchText !== "" && (
                  <Remove24 style={{ cursor: "pointer" }} />
                )}
              </InputGroupText>
            </div>
          </InputGroup>
        </div>
        <div className="col-sm-2">
          <Button
            rounded="pill"
            theme="brand"
            level="primary"
            size="sm"
            style={{
              width: "78px",
            }}
            disabled={
              searchField !== "Select Category" && searchText !== ""
                ? false
                : true
            }
            onClick={() => {
              //onDrnUpdSearch(searchField, searchText);
              validateAndSearch();
            }}
            className="upd-sch-btn mb-4 ft-size"
          >
            Search
          </Button>
        </div>
      </div>
    </>
  );
}
